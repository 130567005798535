import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function Loading() {
  return (
    <div className='mt-5 mb-4 small' style={{textAlign: 'center'}}>
        <Spinner animation="grow" size="sm" variant='secondary'/>
        <Spinner className='mx-2' animation="grow" size="sm" variant='secondary' />
        <Spinner animation="grow" size="sm" variant='secondary' />
    </div>
  )
}
