import React, { useEffect, useState } from 'react'
import './StyleDetails.css'
import { IoMdSend } from 'react-icons/io'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { urlVariable } from '../../../Axios/Axios';

function DetailesTickets() {

  const [data, setData] = useState("")
  const [postdata, setPostData] = useState("")

  // Flag State
  const [flag, setFlag] = useState(false)

  //userImage
  const [image,setImage]=useState({
    img:"/image/default-prof-img.jpg"
  })

  const { state } = useLocation()
  useEffect(() => {
    axios.get(`${urlVariable}/admin/ticket/get_detail/${state}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
        }
      })
      .then(res => {
        console.log(res)
        setData(res.data.data)
      })
      .catch(err => { console.log(err) })
  }, [flag])

  const handelclik = (e) => {
    e.preventDefault()
    console.log(postdata)

    const sendanswer = {
      ticket_id: state,
      message: postdata
    }

    axios.post(`${urlVariable}/admin/ticket/answer`, sendanswer,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
        }
      })
      .then(res => {
        console.log(res)
        console.log(postdata)
        setPostData("")
        setFlag(prev=>!prev)
      })
      .catch(err => { console.log(err) })
  }



  return (
    <div className='main'>
      <div className='content'>

        {
          data && data.map((value) => {
            return (
              <div key={value.id}>
                {
                  value.sender.$ref==="admin" ?
                  <div className='main_admmin'>
                  <div className='background_admin'>
                    <img className='admin_image' src='/image/logo2 (1).png' alt='user' />
                  </div>
                  <p className='admin-text'>{value.description}
                  </p>
                </div>
                  :
                  <div className='main_users'>
                  <p className='users-text'>{value.description}</p>
                  <div className='Background-user'>
                    <img className='user_image' src={image.img?"/image/default-prof-img.jpg":"imagePath"} alt='user' />
                  </div>
                </div>
                }
              </div>
            )
          })
        }

        <form className='form-style' onSubmit={handelclik}>
                  <button type='submit' onClick={handelclik}>
                    <IoMdSend size={20} />
                  </button>
                  <input type='text' value={postdata}
                    onChange={(e) => setPostData(e.target.value)}
                    placeholder='نوشتن متن ..' />
                </form>
      </div>
    </div>
  )
}

export default DetailesTickets