import React from 'react';
import './ToastComp.css';
import { Toast, ToastContainer } from 'react-bootstrap';

export default function ToastComp({ ...props }) {

  function closeToastHandler() {
    return props.onShowToast(false);
  }

  return (
    <ToastContainer position="top-start" className="p-3" style={{ zIndex: 10000 }} containerPosition="absolute">
      <Toast onClose={closeToastHandler} show={props.showToastValue} delay={3000} autohide style={{position: 'fixed', left: '10px', top: '10px'}}>
        <Toast.Header style={{ display: 'flex', justifyContent: 'space-between', borderBottom: 'none', paddingBottom: '0' }}>
          <div style={{ color: props.toastColor }}>
            {props.icon}
            {props.title}
          </div>
        </Toast.Header>
        <Toast.Body style={{backgroundColor: 'var(--bg-color-2)'}}>{props.body}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}
