import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function LoadingForBtn() {
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
        <Spinner animation="border" size="sm" style={{marginLeft: '5px'}} />
        منتظر بمانید
    </div>
  )
}
