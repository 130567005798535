import React, { useState } from 'react';
import './Sidebar.css';
import { FaXmark, FaBars, FaTruckRampBox } from "react-icons/fa6";
import SimpleItem from './SidebarItems/SimpleItem';
import DropdownItem from './SidebarItems/DropdownItem';
import { sidebarItemsData } from '../../Datas/sidebarItemsData';


export default function Sidebar() {

    const [itemsData] = useState(sidebarItemsData)
    const [openSidebar, setOpenSidebar] = useState(true)
    const [openSidebarSm, setOpenSidebarSm] = useState('hidden')

    function closeSidebar() {
        setOpenSidebarSm('hidden')
    }

    return (
        <>
            {/* ----- Main Sidebar ----- */}
            <div className={`sidebar ${(openSidebar && 'flex')}`}>
                <div className="sidebar-header">
                    <div className="sidebar-title-section">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FaTruckRampBox className="logo" style={{ color: "var(--color-1)" }} />
                            <h1 className={`sidebar-title ${(!openSidebar && 'hidden')}`}>
                                بارکده
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="sidebar-body">
                    <ul className="sidebar-list">
                        {
                            itemsData.map((item, index) => {
                                if (!item.subItems.length) {
                                    return (
                                        <SimpleItem
                                            key={index}
                                            title={item.title}
                                            icon={item.icon}
                                            link={item.link}
                                            itemState={!openSidebar && 'hidden'}
                                            clickHandler={closeSidebar}
                                        />
                                    )
                                }
                                return (
                                    <DropdownItem
                                        key={index}
                                        title={item.title}
                                        icon={item.icon}
                                        link={item.link}
                                        subItems={item.subItems}
                                        itemState={!openSidebar && 'hidden'}
                                        clickHandler={closeSidebar}
                                    />
                                )
                            })
                        }
                    </ul>
                </div>
            </div>

            {/* ----- Sidebar in small size ----- */}
            {/* Menu Btn */}
            <div className='sidebar-menu-icon' onClick={() => setOpenSidebarSm('')}>
                <FaBars />
            </div>

            {/* Sm Sidebar */}
            <div className={`sidebar-sm-size ${openSidebarSm}`}>
                <div className="sidebar-sm-header">
                    <div className="sidebar-sm-title">پنل مدیریت بارکده</div>
                    <div className="sidebar-sm-close-btn" onClick={() => setOpenSidebarSm('hidden')}><FaXmark /></div>
                </div>
                <div className="sidebar-sm-body">
                    <ul className="sidebar-sm-list">
                        {
                            itemsData.map((item, index) => {
                                if (!item.subItems.length) {
                                    return (
                                        <SimpleItem
                                            key={index}
                                            title={item.title}
                                            icon={item.icon}
                                            link={item.link}
                                            itemState={!openSidebar && 'hidden'}
                                            clickHandler={closeSidebar}
                                        />
                                    )
                                }
                                return (
                                    <DropdownItem
                                        key={index}
                                        title={item.title}
                                        icon={item.icon}
                                        link={item.link}
                                        subItems={item.subItems}
                                        itemState={!openSidebar && 'hidden'}
                                        clickHandler={closeSidebar}
                                    />
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}
