import React from 'react';
import Breadcrumbs from '../../../Components/Breadcrumbs/Breadcrumbs';

export default function Rules() {
    return (
        <div className="info-list">
            <Breadcrumbs
                items={[
                    { name: 'قوانین', link: '' },
                ]}
            />
        </div>
    )
}
