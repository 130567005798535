import React from 'react';
import './StatisticsCard.css';
import { Link } from 'react-router-dom';


export default function StatisticsCard({ ...props }) {
    return (
        <Link className='link' to={props.link}>
            <div className='statistics-card'>
                {props.icon}
                <div>
                    <div className="title">{props.title}</div>
                    <div className="statstic ">{props.count}</div>
                </div>
            </div>
        </Link>
    )
}
