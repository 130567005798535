import React from 'react';
import './SimpleItem.css';
import { Link, useLocation } from 'react-router-dom';


export default function SimpleItem({ ...props }) {

    const location = useLocation()

    function closeHandler() {
        props.clickHandler()
    }

    return (
        <li
            className={
                location.pathname === props.link ?
                    'sidebar-item active' :
                    'sidebar-item'
            }
            onClick={closeHandler}
        >
            <Link className='link' to={props.link}>
                {props.icon}
                <span className={`sidebar-item-title ${props.itemState}`}>{props.title}</span>
            </Link>
        </li>
    )
}
