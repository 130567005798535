import React, { useEffect } from 'react';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import InfoListTitle from '../../Components/InfoListComponents/InfoListTitle/InfoListTitle';
import InfoListNavigation from '../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import {
  FaUser,
  FaUserCheck,
  FaUserLargeSlash,
  FaCircleXmark,
  FaTrash,
  FaChevronDown,
  FaSquareCheck,
  FaSquareXmark
} from "react-icons/fa6";
import { FaCheckCircle, FaPencilAlt, FaCheck } from 'react-icons/fa';
import { IoMdSettings } from "react-icons/io";
import Loading from '../../Components/Loading/Loading';
import LoadingForBtn from '../../Components/Loading/LoadingForBtn/LoadingForBtn';
import axios from 'axios';
import ToastComp from '../../Components/Toast/ToastComp';
import { handleErrors } from '../../Axios/Axios';
import { CiRead } from "react-icons/ci";
import { CiUnread } from "react-icons/ci";
import AddnewsImg from "../../Assets/addnewsImg.png";
import { Height } from '@mui/icons-material';
import uploadImg from "../../Assets/uploadnewImg.svg";
import './CallList.css';
import { FaUpload } from 'react-icons/fa';
import { GiSettingsKnobs } from "react-icons/gi";
import { VscSettings } from "react-icons/vsc";
import descnewsImg from '../../Assets/mdi_progress-tick.svg';
import closeBtn from '../../Assets/Group (2).svg'
import { IoCloseCircleOutline } from "react-icons/io5";
import MyCarousal from '../../Components/Carousal/MyCarousal';
import { urlVariable } from '../../Axios/Axios';
import { useState } from 'react';
import { VscCallOutgoing } from "react-icons/vsc";
import { VscCallIncoming } from "react-icons/vsc";
import { TfiMoreAlt } from "react-icons/tfi";

import { none } from '@neshan-maps-platform/ol/centerconstraint';



export default function CallList() {
  const [showToast, setShowToast] = useState(false)
  const [toastTitle, setToastTitle] = useState('')
  const [toastBody, setToastBody] = useState('')
  const [toastIcon, setToastIcon] = useState()
  const [toastColor, setToastColor] = useState('')
  const [cargoItem , setCargoItem] = useState({});

  const DescHandler = (value)=>
  {     
    setShowDescModal(true);
    setCargoItem(value);
  }

  const [showDescModal, setShowDescModal] = useState(false)
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return ' ';
    return Intl.DateTimeFormat('fa-IR').format(timestamp)
  };


  const [callList, setCallList] = useState([]);
  useEffect(() => {
    axios.get(`${urlVariable}/admin/call/list`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
        },
      })
      .then((response) => {
        setCallList(response.data.data)
      })
      .catch((error) => {
        setToastTitle('عملیات ناموفق')
        setToastBody('خطا در بارگیری اطلاعات')
        setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
        setToastColor('var(--danger-color)')
        setShowToast(true)
      });
  }, [])



  return (
    <div className="info-list callList">
      <Breadcrumbs
        items={[{ name: 'لیست تماس‌ها', link: '/pannel/calllist' }]}
      />
      <div className="info-list-table-section callList-header">
        {
          callList.length ?
            (<table className='info-list-table '>
              <thead>
                <tr className='info-list-tr-head'>
                  <th>
                    <VscCallOutgoing style={{ marginLeft: '3px', fontSize: '13px' }} />
                    تماس گیرنده
                  </th>
                  <th className='td-res-1'>
                    <VscCallIncoming style={{ marginLeft: '3px', fontSize: '13px' }} />
                    دریافت کننده تماس
                  </th>
                  <th className='td-res-1'>
                    <svg style={{ marginLeft: '3px' }} stroke="currentColor" fill="currentColor" strokeWidth=".3" viewBox="0 0 16 16" height="17px" width="17px" xmlns="http://www.w3.org/2000/svg"><path d="M6 9.5C6.93191 9.5 7.71496 10.1374 7.93699 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7455 13.8231 11.9496 13.5899 11.9919L13.5 12L7.93673 12.001C7.71435 12.8631 6.93155 13.5 6 13.5C5.06845 13.5 4.28565 12.8631 4.06327 12.001L2.5 12C2.22386 12 2 11.7761 2 11.5C2 11.2545 2.17688 11.0504 2.41012 11.0081L2.5 11H4.06301C4.28504 10.1374 5.06809 9.5 6 9.5ZM6 10.5C5.44772 10.5 5 10.9477 5 11.5C5 12.0523 5.44772 12.5 6 12.5C6.55228 12.5 7 12.0523 7 11.5C7 10.9477 6.55228 10.5 6 10.5ZM10 2.5C10.9319 2.5 11.715 3.13738 11.937 3.99998L13.5 4C13.7761 4 14 4.22386 14 4.5C14 4.74546 13.8231 4.94961 13.5899 4.99194L13.5 5L11.9367 5.00102C11.7144 5.86312 10.9316 6.5 10 6.5C9.06845 6.5 8.28565 5.86312 8.06327 5.00102L2.5 5C2.22386 5 2 4.77614 2 4.5C2 4.25454 2.17688 4.05039 2.41012 4.00806L2.5 4L8.06301 3.99998C8.28504 3.13738 9.06809 2.5 10 2.5ZM10 3.5C9.44772 3.5 9 3.94772 9 4.5C9 5.05228 9.44772 5.5 10 5.5C10.5523 5.5 11 5.05228 11 4.5C11 3.94772 10.5523 3.5 10 3.5Z"></path></svg>
                    تاریخ تماس
                  </th>
                  <th >
                    <IoMdSettings style={{ marginLeft: '3px', fontSize: '17px' }} />
                    اطلاعات بار
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  callList && callList.map((value, index) => {
                    return (
                      <tr key={index} className='info-list-row'>
                        <td >{value.Caller_detail[0] ? value.Caller_detail[0].phoneNumber : "-"}</td>
                        <td  className='td-res-1'><p>{(value.user_detail && value.user_detail[0] ) ? value.user_detail[0].companyOwnerName || value.user_detail[0].name  : "-"}</p>
                        <p>{(value.user_detail &&  value.user_detail[0]) ? value.user_detail[0].phoneNumber : "-"}</p>
                        </td>
                        <td className='td-res-1'>{formatTimestamp(value.date)}</td>
                        <td>
                          <p>{value.cargo.origin && value.cargo.origin[0] ?  value.cargo.origin[0].name : ""}-{value.cargo.destination && value.cargo.destination[0] ? value.cargo.destination[0].name : ""}</p> 
                          
                          <span>{value.cargo.typeCargo} / </span>           
                          <span onClick={() => DescHandler(value.cargo)}  style={{color : 'var(--color-1)', cursor : 'pointer'}}>   جزییات    </span>          
                         
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>) : (
              <Loading />
            )
        }
      </div>

    
      {/* Pagination Section */}
      {/* <div className="pagination-section">
        <div className='pagination-btn'>قبلی</div>
        <div>صفحه 1 از 1</div>
        <div className='pagination-btn'>بعدی</div>
      </div> */}

      <ToastComp
        title={toastTitle}
        body={toastBody}
        icon={toastIcon}
        onShowToast={setShowToast}
        showToastValue={showToast}
        toastColor={toastColor}
      />

      <Modal
        show={showDescModal}
        centered
        className='callList'
      >
        <Modal.Header>
          <Modal.Title id='newsDesc' style={{ width: '100%', display: 'flex' }}>
            جزییات بار
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container style={{ textAlign: 'right', fontWeight: 'normal' }}>
          <Row>
              <Col xs={12} className='mt-3'>
                <div className='modal-input-container'>
                  <p >مسیر : {cargoItem.origin ? cargoItem.origin[0].name : ''} - {cargoItem.destination ? cargoItem.destination[0].name : ''} </p>

                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='mt-3'>
                <div className='modal-input-container'>
                  <p >نوع بار : {cargoItem.typeCargo ? cargoItem.typeCargo : "-"}</p>

                </div>
              </Col>
            </Row>
            
            <Row>
              <Col xs={12} className='mt-3'>
                <div className='modal-input-container'>
                  <p >قیمت بار :  {cargoItem.price ? cargoItem.price : "-"}</p>

                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='mt-3'>
                <div className='modal-input-container'>
                  <p > تناژ بار آزاد :  {cargoItem.freeweight == false ? "غیرفعال" : "فعال"}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='mt-3'>
                <div className='modal-input-container'>
                  <p >وزن بار :  {cargoItem.weight ? cargoItem.weight : "-"}</p>

                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='mt-3'>
                <div className='modal-input-container'>
                  <p >نوع ماشین :  {cargoItem.typeCar &&  cargoItem.typeCar[0] ? cargoItem.typeCar[0].name : "-"}</p>

                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='mt-3'>
                <div className='modal-input-container'>
                  <p > شماره تلفن صاحب بار :  {cargoItem.phoneNumber ? cargoItem.phoneNumber : "-"}</p>

                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className='mt-3'>
                <div className='modal-input-container'>
                  <p >تاریخ ایجاد بار : {formatTimestamp(cargoItem.createdIn)}</p>

                </div>
              </Col>
            </Row>
          
            <Row>
              <Col xs={12} className='mt-3'>
                <div className='modal-input-container'>
                  <p >وضعیت بار : {cargoItem.status == "active" ?  "فعال" : "غیر فعال"}</p>

                </div>
              </Col>
            </Row>
          
          
           
          
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none', justifyContent: 'space-between' }}>
             <button className='modal-btn modal-cancle-btn' onClick={() => setShowDescModal(false)}>
              بازگشت
            </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
