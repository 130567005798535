import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
  FaCircleXmark,
  FaTrash,
  FaUser,
  FaTicket,
  FaCalendarDays
} from "react-icons/fa6";
import { IoIosCloseCircle, IoMdSettings } from "react-icons/io";
import Loading from '../../../Components/Loading/Loading';
import axios from 'axios';
import Breadcrumbs from '../../../Components/Breadcrumbs/Breadcrumbs';
import ToastComp from '../../../Components/Toast/ToastComp';
import jalaali from 'jalaali-js'; // اضافه کردن کتابخانه تبدیل به شمسی
import { Link } from 'react-router-dom';
import { urlVariable } from '../../../Axios/Axios';
import LoadingForBtn from '../../../Components/Loading/LoadingForBtn/LoadingForBtn';

export default function TicketsList() {
  // Loading State
  const [loading, setLoading] = useState(false);
 

  // Toast State
  const [toastData, setToastData] = useState({
    showToast: false,
    title: "",
    body: "",
    icon: "",
    color: ""
  });


const [flag,setFlag] = useState(false)

  // Modal States
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Get Tickets
  const [allTickets, setAllTickets] = useState([]);
  console.log(allTickets)
  useEffect(() => {
    setLoading(true);
    axios.get(`${urlVariable}/admin/ticket/get_list`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
      }
    })
    .then(res => {
      setLoading(false);
      setAllTickets(res.data.data);
    })
    .catch(err => {
      setLoading(false);
      console.log(err);
    });
  }, [flag]);

  // آرایه روزهای هفته به شمسی
  const weekDays = ['یکشنبه', 'دوشنبه', 'سه‌شنبه', 'چهارشنبه', 'پنج‌شنبه', 'جمعه', 'شنبه'];

  // تبدیل میلی‌ثانیه به فرمت تاریخ شمسی و نمایش روز هفته
  const formatDateToJalali = (milliseconds) => {
    const date = new Date(milliseconds);
    const dayOfWeek = weekDays[date.getDay()];
    const jalaliDate = jalaali.toJalaali(date);
    const formattedDate = `${dayOfWeek} ${jalaliDate.jy}/${jalaliDate.jm}/${jalaliDate.jd} ${date.getHours()}:${date.getMinutes()}`;
    return formattedDate;
  };

  //گرفتن ایدی تیکت برای حذف
  const [ticketId, setTicketId] = useState("");
  const getItemId = (itemId) => {
    setShowDeleteModal(true);
    setTicketId(itemId);
  };
  //تابع حذف
  const handleDelete = () => {
    const item = { ticket_id: ticketId };
    setLoading(true)
    axios.delete(`${urlVariable}/admin/ticket/delete`, {
      data: item,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
      }
    })
    .then(res => {
   if(res.status == 200){
    setToastData({
      showToast: true,
      title: "عملیات موفق",
      body: res.data.msg,
      icon: <FaCircleXmark style={{ marginLeft: '5px' }} />,
      color: 'var(--success-color)'
    });
    setShowDeleteModal(false);
    setLoading(false)
    setFlag(true)
   }else{
    setFlag(false)

   }
    })
    .catch(err => {
      setToastData({
        showToast: true,
        title: "  عملیات نا موفق",
        body: err.response?.data?.msg || "خطای غیر منتظره رخ داد",
        icon: <FaCircleXmark style={{ marginLeft: '5px' }} />,
        color: 'var(--danger-color)'
      });
      setShowDeleteModal(false);
      setLoading(false)

    });
  };

  return (
    <>
      <div className="info-list">
        <Breadcrumbs items={[{ name: 'تیکت‌ها ', link: '/pannel/tickets/list' }]} />

        {toastData.showToast && (
          <ToastComp
            title={toastData.title}
            body={toastData.body}
            icon={toastData.icon}
            onShowToast={setToastData}
            showToastValue={toastData.showToast}
            toastColor={toastData.color}
          />
        )}

        <div className="info-list-table-section">
          {loading ? (
            <Loading />
          ) : (
            <table className='info-list-table'>
              <thead>
                <tr className='info-list-tr-head'>
                  <th>
                    <FaUser style={{ marginLeft: '3px', fontSize: '13px' }} />
                    کاربر
                  </th>
                  <th className='td-res-1'>
                    <FaTicket style={{ marginLeft: '3px', fontSize: '13px' }} />
                    موضوع تیکت
                  </th>
                  <th className='td-res-1'>
                    <FaCalendarDays style={{ marginLeft: '3px', fontSize: '13px' }} />
                    زمان ارسال تیکت
                  </th>
                  <th>
                    <IoMdSettings style={{ marginLeft: '3px', fontSize: '13px' }} />
                    عملیات
                  </th>
                </tr>
              </thead>
              <tbody>
                {allTickets.length ? allTickets.map((value) => {
                  console.log(value);
                  return (
                    <tr key={value._id.$oid} className='info-list-row'>
                      <td>{value.creator[0] ? value.creator[0].name : '-'}</td>
                      <td className='td-res-1'>{value.sbject}</td>
                      <td className='td-res-1'>{formatDateToJalali(value.createdAt)}</td>
                      <td>
                        <Link className='link' to={"/pannel/tickets/detailes"} state={value._id.$oid}>
                          <span style={{ color: 'var(--color-1)', cursor: 'pointer' }}>نمایش</span>
                        </Link>
                        <span style={{ margin: '0 3px' }}>/</span>
                        <span style={{ color: 'var(--danger-color)', cursor: 'pointer' }} 
                        onClick={() => getItemId(value._id.$oid)}>حذف</span>
                      </td>
                    </tr>
                  )
                }) : (
                  <tr>
                    <td colSpan="4" style={{ textAlign: 'center', padding: '10px 0', backgroundColor: 'var(--bg-color-2)' }}>
                      در حال حاضر تیکتی نداریم!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>

        {/* Pagination Section */}
        <div className="pagination-section">
          <div className='pagination-btn'>قبلی</div>
          <div>صفحه 1 از 1</div>
          <div className='pagination-btn'>بعدی</div>
        </div>
      </div>

      {/* Delete Modal */}
      <Modal show={showDeleteModal} centered>
        <Modal.Header>
          <Modal.Title style={{ width: '100%' }}>
            <div className="modal-title-section">
              <span className="modal-title">حذف مسافر</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          آیا از حذف کاربر اطمینان دارید؟
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none' }}>
          <button className='modal-btn modal-cancle-btn' onClick={() => setShowDeleteModal(false)}>لغو</button>
          <button className='modal-btn modal-red-btn' onClick={handleDelete}>
           {
            loading?
          
            <LoadingForBtn/>
              :
              <>
            <loading/>
            <FaTrash className='icon' /> حذف</>
          } 
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
