import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import { Container, Row, Col } from 'react-bootstrap';
import StatisticsCard from '../../Components/StatisticsCard/StatisticsCard';
import { BsFillPatchCheckFill } from "react-icons/bs";
import axios from 'axios';
import { urlVariable } from '../../Axios/Axios';
import CargoOwners from '../Users/CargoOwners/CargoOwners';

export default function UserStatistics() {
    const [userCount, setUserCount] = useState("");
    useEffect(() => {
        axios.get(`${urlVariable}/admin/user/`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
                },
                params:
                {
                    status: 'active'
                }
            }
        )
            .then(response => {
                setUserCount(response.data.total_count);
            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    const [cargoOwnersCount, setCargoOwnersCount] = useState("");
    useEffect(() => {
        axios.get(`${urlVariable}/admin/user/`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
                },
                params:
                {
                    role: 'cargoOwners',
                    status: 'active'

                }
            }
        )
            .then(response => {
                setCargoOwnersCount(response.data.total_count);
            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    const [driverCount, setDriverCount] = useState("");
    useEffect(() => {
        axios.get(`${urlVariable}/admin/user/`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
                },
                params:
                {
                    status: 'active',
                    role: 'driver'
                }
            }
        )
            .then(response => {
                setDriverCount(response.data.total_count);
            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    const [curatorCount, setCuratorCount] = useState("");
    useEffect(() => {
        axios.get(`${urlVariable}/admin/user/`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
                },
                params:
                {
                    role: 'Curator',
                    status: 'active'
                }
            }
        )
            .then(response => {
                setCuratorCount(response.data.total_count);
            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    const [freightCount, setFreightCount] = useState("");
    useEffect(() => {
        axios.get(`${urlVariable}/admin/user/`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
                },
                params:
                {
                    role: 'freight',
                    status: 'active'
                }
            }
        )
            .then(response => {
                setFreightCount(response.data.total_count);
            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    return (
        <div className="info-list">
            <Breadcrumbs
                items={[
                    { name: 'آمار کاربران', link: '/pannel/statistics/users' },
                ]}
            />
            <div className='mt-4'>
                <Container>
                    <Row>
                        <Col xs={12} md={4} xl={3}>
                            <StatisticsCard
                                link='/pannel/users/all'
                                title='کل کاربران'
                                count={userCount}
                                icon={<BsFillPatchCheckFill className='icon' />}
                            />
                        </Col>
                        <Col xs={12} md={4} xl={3}>
                            <StatisticsCard
                                link='/pannel/users/driver'
                                title='رانندگان'
                                count={driverCount}
                                icon={<BsFillPatchCheckFill className='icon' />}
                            />
                        </Col>
                        <Col xs={12} md={4} xl={3}>
                            <StatisticsCard
                                link='/pannel/users/curators'
                                title='متصدیان'
                                count={curatorCount}
                                icon={<BsFillPatchCheckFill className='icon' />}
                            />
                        </Col>
                        <Col xs={12} md={4} xl={3}>
                            <StatisticsCard
                                link='/pannel/users/freight'
                                title='باربری‌ها'
                                count={freightCount}
                                icon={<BsFillPatchCheckFill className='icon' />}
                            />
                        </Col>
                        <Col xs={12} md={4} xl={3}>
                            <StatisticsCard
                                link='/pannel/users/cargoOwners'
                                title='صاحبین بار'
                                count={cargoOwnersCount}
                                icon={<BsFillPatchCheckFill className='icon' />}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
