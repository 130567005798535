import './App.css';
import { useState } from 'react';
import Login from './Pages/Login/Login';
import Pannel from './Pages/Pannel/Pannel';

function App() {

  const [accessToken, setAccessToken] = useState(localStorage.getItem("AccessToken"))

  return (
    <div className="App">
      {accessToken ? <Pannel /> : <Login />}
    </div>
  );
}




export default App;
