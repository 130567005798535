import React, { useEffect, useState } from 'react';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FaRegMoon } from "react-icons/fa";
import { FaRegMessage, FaPowerOff } from "react-icons/fa6";
import { ImNotification } from "react-icons/im";
import profileImg from './public/default-prof-img.jpg';
import { urlVariable } from '../../Axios/Axios';
import axios from 'axios';

export default function Header() {

  const navigate = useNavigate()
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  function logOutHandler() {
    localStorage.clear()
    navigate('/login')
  }

  // Get My Profile
  const [userName, setUserName] = useState('')
  useEffect(() => {
    axios.get(`${urlVariable}/admin/profile`,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
    }
    })
    .then(res=>{
      if(res.status===200){
        setUserName(res.data.data.username)
      }
    })
    .catch(err=>console.log(err))

  }, [])


  return (
    <>
      <header className='header'>
        <div className="header-content">
          {/* <Link className='link' to='/pannel/support/tickets'>
            <div className="icon-badge-container">
              <span className='badge'>4</span>
              <FaRegMessage className='header-icon' />
            </div>
          </Link>
          <FaRegMoon className='header-icon' /> */}
          <div onClick={() => setShowLogoutModal(true)} style={{ cursor: 'pointer' }}>
            <img className='header-img' src={profileImg} />
            <span className='me-2'>{userName}</span>
          </div>
          {/* <FaPowerOff className='header-icon' onClick={() => setShowLogoutModal(true)} /> */}
        </div>
      </header>

      {/* Log Out Modal */}
      <Modal
        show={showLogoutModal}
        centered
      >
        <Modal.Body>
          <div>آیا از خروج مطمئن هستید؟</div>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none' }}>
          <button className='exit-modal-btn exit-btn' onClick={logOutHandler}>خروج</button>
          <button className='exit-modal-btn cancel-exit' onClick={() => setShowLogoutModal(false)}>انصراف</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
