import React, {useState, useEffect} from 'react';
import InfoListTitle from '../../../Components/InfoListComponents/InfoListTitle/InfoListTitle';
import InfoListNavigation from '../../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation';
import {Col, Modal, Row} from 'react-bootstrap';
import {
    FaUser,
    FaUserCheck,
    FaUserLargeSlash,
    FaCircleXmark,
    FaTrash, FaChevronDown, FaUserPlus, FaChevronUp
} from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import {FaCheckCircle} from "react-icons/fa";
import Loading from '../../../Components/Loading/Loading';
import axios from 'axios';
import Breadcrumbs from '../../../Components/Breadcrumbs/Breadcrumbs';
import ToastComp from '../../../Components/Toast/ToastComp';
import {FaDumpsterFire, FaPhone, FaUserTie, FaEdit} from 'react-icons/fa';
import {handleErrors, urlVariable} from '../../../Axios/Axios';
import LoadingForBtn from "../../../Components/Loading/LoadingForBtn/LoadingForBtn";
import {IoFilter} from "react-icons/io5";
import {IoMdSettings} from "react-icons/io";


export default function CuratorUsers() {


    // Toast State
    const [showToast, setShowToast] = useState(false)
    const [toastTitle, setToastTitle] = useState('')
    const [toastBody, setToastBody] = useState('')
    const [toastIcon, setToastIcon] = useState()
    const [toastColor, setToastColor] = useState('')

    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [totalData, setTotalData] = useState()

    const [showDropdown, setShowDropdown] = useState("");



    // value for filter users
    const [userFilter, setUserFilter] = useState('')
    const [phoneNumberFilter, setPhoneNumberFilter] = useState('')
    const [userFilterApi, setUserFilterApi] = useState('')
    const [phoneNumberFilterApi, setPhoneNumberFilterApi] = useState('')

    const [loading, setLoading] = useState(false)
    const [loadingDropdown, setLoadingDropdown] = useState(false)
    const [loadingForBtn, setLoadingForBtn] = useState(false)
    const [refreshData, setRefreshData] = useState(false)

    // Modal States
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showAddCurator, setShowAddCurator] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [hasFilterShow, setHasFilterShow] = useState(false)

    const [users, setUsers] = useState([])
    const [userID, setUserID] = useState('')
    const [statusURL, setStatusURl] = useState("active")

    // value for get city list
    const [cityList, setCityList] = useState([])
    const [searchCityShow, setSearchCityShow] = useState("")
    const [searchCityApi, setSearchCityApi] = useState("")


    const [name, setName] = useState("")
    const [codeMely, setCodeMely] = useState("")
    const [PhoneNumber, setPhoneNumber] = useState("")
    const [typeCar, setTypeCar] = useState("")
    const [TypeCarID, setTypeCarID] = useState("")
    const [typeCarList, setTypeCarList] = useState([])
    const [city, setCity] = useState("")
    const [cityID, setCityID] = useState("")
    const [lastLogin, setLastLogin] = useState('')
    const [lastModified, setLastModified] = useState('')
    const [phoneStatus, setPhoneStatus] = useState("")
    const [registered, setRegistered] = useState("")
    const [saveCargo, setSaveCargo] = useState("")
    const [status, setStatus] = useState({
        show: "",
        title: "",
        realData: "",
    });


    useEffect(() => {
        setLoading(true)
        axios.get(`${urlVariable}/admin/user/?role=Curator`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
                },
                params: {
                    limit: limit,
                    page: page,
                    status: statusURL,
                    name: userFilterApi,
                    phone_Number: phoneNumberFilterApi
                }
            })
            .then((res) => {
                setTotalPage(res.data.total_pages)
                setTotalData(res.data.total_count)
                setUsers(res.data.data)
                setLoading(false)
            })
            .catch(err => {
                handleErrors(err)
                if (err.message === "Network Error") {
                    setToastTitle('عملیات ناموفق')
                    setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setShowToast(true)
                    setLoading(false)
                } else {
                    if (err.response.status >= 500) {
                        setToastTitle('عملیات ناموفق')
                        setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                        setToastColor('var(--danger-color)')
                        setShowToast(true)
                        setLoading(false)
                        setToastBody("خطای رخ در سرور داده لطفا دوباره تلاش کتید!..")
                    } else {
                        setToastTitle('عملیات ناموفق')
                        setToastBody('خطا در بارگیری اطلاعات')
                        setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                        setToastColor('var(--danger-color)')
                        setShowToast(true)
                        setLoading(false)
                    }
                }
            })
    }, [statusURL, refreshData, page, userFilterApi, phoneNumberFilterApi])


    useEffect(() => {    // get city list api

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/groups/city`, {
            params: {
                search: searchCityApi
            }
        })
            .then((res) => {
                setCityList(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                setLoadingDropdown(true)
            })

    }, [searchCityApi])


    useEffect(() => {

        const delayedSetData = setTimeout(() => {
            setSearchCityApi(searchCityShow)
        }, 800)

        return () => clearTimeout(delayedSetData);

    }, [searchCityShow]);

    useEffect(() => {
        setPage(1)
    }, [statusURL]);


    useEffect(() => {

        setName("")
        setCodeMely("")
        setPhoneNumber("")
        setCityID("")
        setTypeCarID("")
        setCity("")
        setTypeCar("")
        setShowDropdown("")
        setSearchCityShow("")

    }, [showAddCurator]);

    useEffect(() => {    // get car list api

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/car/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        })
            .then((res) => {
                setTypeCarList(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingDropdown(true)
            })

    }, [])

    useEffect(() => {

        if ((page > totalPage) && (totalPage > 0))
            setPage(totalPage)

    }, [totalPage]);

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ' ';
        return Intl.DateTimeFormat('fa-IR').format(timestamp)
    };

    function Dropdown(value) {
        if (showDropdown === value)
            setShowDropdown('')
        else setShowDropdown(value)
    }

    function selectCity(value) {
        setCityID(value._id.$oid)
        setCity(value.name)
        setShowDropdown('')
    }

    function selectStatus(show, title) {
        setStatus({
            show: show,
            title: title
        })
        setShowDropdown("")
    }

    function selectCar(value) {
        setTypeCarID(value._id.$oid)
        setTypeCar(value.name)
        setShowDropdown('')

    }

    function setFilterForApi () {
        setUserFilterApi(userFilter)
        setPhoneNumberFilterApi(phoneNumberFilter)
        setHasFilterShow(true)
        setShowFilterModal(false)
    }

    function setRemoveAllFilter () {
        setUserFilterApi("")
        setPhoneNumberFilterApi("")
        setUserFilter("")
        setPhoneNumberFilter("")
        setHasFilterShow(false)
    }

    function Delete(value) {
        console.log(value)
        setShowDeleteModal(true)
        setUserID(value._id?.$oid)
    }

    function RemoveFromApi() {

        setLoadingForBtn(true)
        axios.delete(`${urlVariable}/admin/user/delete`, {
            data: {
                _id: userID,
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
            }
        }).then((res) => {
            setShowToast(true)
            setToastTitle("عملیات موفق")
            setToastBody("کاربر مورد نظر با موفقیت حذف شد")
            setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
            setToastColor('var(--color-1)')
            setShowDeleteModal(false)
            setLoadingForBtn(false)
            setRefreshData(!refreshData)
        }).catch((err) => {
            handleErrors(err)
            if (err.message === "Network Error") {
                setShowToast(true)
                setToastTitle('عملیات ناموفق')
                setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowDeleteModal(false)
            }
            if (err.response && err.response.status <= 499) {
                setShowToast(true)
                setToastTitle("عملیات ناموفق")
                setToastBody(err.response.data.msg)
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowDeleteModal(false)
            } else {
                if (err.response && err.response.status >= 500) {
                    setToastBody("خطای رخ در سرور داده لطفا دوباره تلاش کتید!..")
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowDeleteModal(false)
                } else {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody("خطا در بارگیری اطلاعات")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowDeleteModal(false)
                }
            }
        })
    }

    function Edit(value) {

        console.log(value)
        setUserID(value._id?.$oid || "-")
        setName(value.name || "-")
        setCodeMely(value.codeMely || "-")
        setPhoneNumber(value.phoneNumber || "-")
        setTypeCar(value.carType[value.carType.length - 1]?.name || "-")
        setCity(value.city[value.city.length - 1]?.name || "-")
        setLastLogin(formatTimestamp(value.lastLogin) || "-")
        setLastModified(formatTimestamp(value.lastModified) || "-")
        if (value.phoneStatus) {
            if (value.phoneStatus === "Confirmed")
                setPhoneStatus("تاییده شده")
            else setPhoneStatus("تاییده نشده یا نا معتبر")
        } else setPhoneStatus("-")
        setRegistered(formatTimestamp(value.registeredIn) || "-")
        setSaveCargo(value.savecargoes ? (value.savecargoes[value.savecargoes.length - 1]?.$ref || "-") : (value.savecargoes || "-"))
        setShowEditModal(true)
        if (value.status) {
            if (value.status === "profile_completed")
                setStatus({
                    show: "اطلاعات تکمیل و تایید شده",
                    title: value.status,
                    realData: value.status,
                })
            if (value.status === "profile_not_completed")
                setStatus({
                    show: "اطلاعات تکمیل نشده",
                    title: value.status,
                    realData: value.status,
                })
            if (value.status === "deleted")
                setStatus({
                    show: "کاربر حذف شده",
                    title: value.status,
                    realData: value.status,
                })
        } else setStatus({show: "-", title: "-", realData: "-"})
        setShowDropdown("")
    }

    function addUser() {

        const info = {
            phoneNumber: PhoneNumber,
            name: name,
            carType: TypeCarID,
            city: cityID,
            codeMely: codeMely,
        }

        setLoadingForBtn(true)
        axios.post(`${urlVariable}/admin/user/curator`, info, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
            }
        }).then((res) => {
            setShowToast(true)
            setToastTitle("عملیات موفق")
            setToastBody("متصدی مورد نظر با موفقیت ثبت شد")
            setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
            setToastColor('var(--color-1)')
            setShowAddCurator(false)
            setLoadingForBtn(false)
            setRefreshData(!refreshData)
        }).catch((err) => {
            handleErrors(err)
            if (err.message === "Network Error") {
                setShowToast(true)
                setToastTitle('عملیات ناموفق')
                setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowAddCurator(true)
            }
            if (err.response && err.response.status <= 499) {
                setShowToast(true)
                setToastTitle("عملیات ناموفق")
                setToastBody(err.response.data.msg)
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowAddCurator(true)
            } else {
                if (err.response && err.response.status >= 500) {
                    setToastBody("خطای رخ در سرور داده لطفا دوباره تلاش کتید!..")
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowAddCurator(true)
                } else {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody("خطا در بارگیری اطلاعات")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowAddCurator(true)
                }
            }
        })

    }

    function EditFromApi() {

        const info = {
            user_id: userID,
            name: name,
            codeMely: codeMely,
            city: cityID,
            status: status.title
        }

        setLoadingForBtn(true)
        axios.put(`${urlVariable}/admin/user/edit`, info, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
            }
        }).then((res) => {
            setShowToast(true)
            setToastTitle("عملیات موفق")
            setToastBody(res.data.msg)
            setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
            setToastColor('var(--color-1)')
            setLoadingForBtn(false)
            setShowEditModal(false)
            setRefreshData(!refreshData)
        }).catch((err) => {
            handleErrors(err)
            if (err.message === "Network Error") {
                setShowToast(true)
                setToastTitle('عملیات ناموفق')
                setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید!..')
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowEditModal(true)
            }
            if (err.response && err.response.status <= 499) {
                setShowToast(true)
                setToastTitle("عملیات ناموفق")
                setToastBody(err.response.data.msg)
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                setToastColor('var(--danger-color)')
                setLoadingForBtn(false)
                setShowEditModal(true)
            } else {
                if (err.response && err.response.status >= 500) {
                    setToastBody("خطای رخ در سرور داده لطفا دوباره تلاش کتید!..")
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowEditModal(true)
                } else {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody("خطا در بارگیری اطلاعات")
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--danger-color)')
                    setLoadingForBtn(false)
                    setShowEditModal(true)
                }
            }
        })
    }


    return (
        <>
            <div className="info-list">
                <Breadcrumbs
                    items={[{name: 'کاربران', link: '/pannel/users/all'},
                        {name: 'متصدیان', link: '/pannel/users/curators'}]}
                />
                <InfoListNavigation
                    btntitle='افزودن متصدی جدید'
                    // btnLink='/pannel/users/adduser'
                    onShowModal={setShowAddCurator}
                    setStatus={setStatusURl}
                    hasFilterShow={hasFilterShow}
                    hasFilter={true}
                    onShowFilter={setShowFilterModal}
                    status={statusURL}
                    items={[
                        ['کاربران فعال', 'active', <FaUserCheck className='icon'/>],
                        ['کاربران غیرفعال', 'deleted', <FaUserLargeSlash className='icon'/>]
                    ]}
                />

                <div className="info-list-table-section">
                    <table className='info-list-table'>
                        <thead>
                        <tr className='info-list-tr-head'>
                            <th>
                                <FaUser style={{marginLeft: '3px', fontSize: '13px'}}/>
                                نام و نام‌خانوادگی
                            </th>
                            <th className='td-res-1'>
                                <FaPhone style={{marginLeft: '3px', fontSize: '13px'}}/>
                                شماره موبایل
                            </th>
                            <th className='td-res-1'>
                                <FaUserTie style={{marginLeft: '3px', fontSize: '13px'}}/>
                                کدملی
                            </th>
                            <th>
                                <IoMdSettings style={{marginLeft: '3px', fontSize: '16px'}}/>
                                عملیات
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {loading ?
                            <tr>
                                <td colSpan='4'>
                                    <Loading/>

                                </td>
                            </tr>
                            :
                            (users.length ?
                                users && users.map((value) => {
                                    return (
                                        <tr key={value._id?.$oid} className='info-list-row'>
                                            <td>{value.name}</td>
                                            <td className='td-res-1'>{value.phoneNumber}</td>
                                            <td className='td-res-1'>{value.codeMely}</td>
                                            <td>
                                                    <span style={{color: 'var(--color-1)', cursor: 'pointer'}}
                                                          onClick={() => Edit(value)}>ویرایش</span>
                                                {value.status !== "deleted" &&
                                                    <>
                                                        <span style={{margin: '0 3px'}}>/</span>
                                                        <span style={{color: 'var(--danger-color)', cursor: 'pointer'}}
                                                              onClick={() => Delete(value)}>حذف</span>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }) :
                                <tr>
                                    <td colSpan="4" style={{
                                        textAlign: 'center',
                                        padding: '10px 0',
                                        backgroundColor: 'var(--bg-color-2)'
                                    }}>در حال حاضر کاربری نداریم!
                                    </td>
                                </tr>)
                        }
                        </tbody>
                    </table>
                </div>

                {/* Pagination Section */}
                {totalData > limit ?
                    <div className="pagination-section">
                        <button className='pagination-btn' disabled={page <= 1}
                                style={page === 1 ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page - 1)}>قبلی
                        </button>
                        <div>صفحه {page} از {totalPage}</div>
                        <button className='pagination-btn' disabled={page >= totalPage}
                                style={page === totalPage ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page + 1)}>بعدی
                        </button>
                    </div>

                    :
                    null}

            </div>

            {/* Toast */}
            <ToastComp
                title={toastTitle}
                body={toastBody}
                icon={toastIcon}
                onShowToast={setShowToast}
                showToastValue={showToast}
                toastColor={toastColor}
            />

            {/* Delete Modal */}
            <Modal
                show={showDeleteModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">حذف متصدی</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    آیا از حذف کاربر اطمینان دارید؟
                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowDeleteModal(false)}>لغو
                    </button>
                    <button className='modal-btn modal-red-btn' onClick={RemoveFromApi}>
                        {loadingForBtn ?
                            <LoadingForBtn/>
                            :
                            <>
                                <FaTrash className='icon'/>حذف
                            </>}
                    </button>
                </Modal.Footer>
            </Modal>

            {/*edit modal*/}
            <Modal
                show={showEditModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">ویرایش متصدی</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div style={{textAlign: "right"}}>

                        <Row>
                            <Col>
                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container' onClick={() => Dropdown("status")}>
                                        <label style={{textAlign: "right"}}>
                                            وضعیت
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={status.show}/>
                                            {status.realData === "profile_completed" ?
                                                null
                                                :
                                                showDropdown === "status" ? (<FaChevronUp className='icon'/>) :
                                                    (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {status.realData === "profile_completed" ?
                                        null
                                        :
                                        showDropdown === "status" ?
                                            <ul className="modal-dropdown-list"
                                                style={{textAlign: "right"}}>
                                                <li onClick={() => selectStatus("تایید شده", "profile_completed")}>
                                                    تایید شده
                                                </li>

                                            </ul>
                                            : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='modal-input-container'>
                                    <label>نام و نام خانوادگی</label>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                                </div>
                            </Col>
                            <Col>
                                <div className='modal-input-container'>
                                    <label>شماره موبایل</label>
                                    <input type="number" value={PhoneNumber}
                                           onChange={(e) => setPhoneNumber(e.target.value)}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='modal-input-container'>
                                    <label>کد ملی</label>
                                    <input type="number" value={codeMely}
                                           onChange={(e) => setCodeMely(e.target.value)}/>
                                </div>
                            </Col>
                            <Col>
                                <div className='modal-input-container'>
                                    <label>وضعیت شماره موبایل</label>
                                    <input type="text" value={phoneStatus}/>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container'>
                                        <label>شهر</label>
                                        <div className='modal-dropdown-input-container'
                                             onClick={() => Dropdown("city")}>
                                            <input value={city}/>
                                            {showDropdown === "city" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>
                                    {
                                        showDropdown === "city" &&
                                        <ul className="modal-dropdown-list">
                                            <input className='search-dropdown-input' placeholder="جست و جو ..." autoComplete='off' value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityList.length ?
                                                    cityList.map((item, index) =>
                                                        <li key={index} onClick={() => selectCity(item)}>{item.name}</li>
                                                    ) :
                                                    <li>شهری یافت نشد!..</li>
                                            }

                                        </ul>
                                    }
                                </div>
                            </Col>
                            <Col>
                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container'>
                                        <label>نوع ناوگان</label>
                                        <div className='modal-dropdown-input-container' onClick={() => Dropdown("car")}>
                                            <input value={typeCar ?? "-"}/>
                                            {showDropdown === "car" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>
                                    {
                                        showDropdown === "car" &&
                                        <ul className="modal-dropdown-list">
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                typeCarList && typeCarList.map((item, index) =>
                                                    <li key={index} onClick={() => selectCar(item)}>{item.name}</li>
                                                )}

                                        </ul>
                                    }
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <div className='modal-input-container'>
                                    <label>ایجاد شده در</label>
                                    <input type="text" value={registered}/>
                                </div>
                            </Col>
                            <Col>
                                <div className='modal-input-container'>
                                    <label>آخرین ورود</label>
                                    <input type="text" value={lastLogin}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='modal-input-container'>
                                    <label>آخرین ویرایش</label>
                                    <input type="text" value={lastModified}/>
                                </div>
                            </Col>
                            <Col>
                                <div className='modal-input-container'>
                                    <label>ذخیره بار</label>
                                    <input type="text" value={saveCargo}/>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowEditModal(false)}>لغو
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={EditFromApi}>
                        {loadingForBtn ?
                            <LoadingForBtn/>
                            :
                            <>
                                <FaEdit className='icon'/>ویرایش
                            </>}
                    </button>
                </Modal.Footer>
            </Modal>

            {/* add Modal */}
            <Modal
                show={showAddCurator}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">افزودن متصدی جدید</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <div style={{textAlign: "right"}}>
                        <Row>
                            <Col xs={12}>
                                <div className='modal-input-container'>
                                    <label>نام و نام خانوادگی</label>
                                    <input type="text" onChange={(e) => setName(e.target.value)}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className='modal-input-container'>
                                    <label>شماره موبایل</label>
                                    <input type="number" onChange={(e) => setPhoneNumber(e.target.value)}/>
                                </div>
                            </Col>
                        </Row><Row>
                        <Col xs={12}>
                            <div className='modal-input-container'>
                                <label>کد ملی</label>
                                <input type="number" onChange={(e) => setCodeMely(e.target.value)}/>
                            </div>
                        </Col>
                    </Row>

                        <Row>
                            <Col xs={12}>
                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container'>
                                        <label>شهر</label>
                                        <div className='modal-dropdown-input-container'
                                             onClick={() => Dropdown("city")}>
                                            <input value={city}/>
                                            {showDropdown === "city" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>
                                    {
                                        showDropdown === "city" &&
                                        <ul className="modal-dropdown-list">
                                            <input className='search-dropdown-input' placeholder="جست و جو ..." autoComplete='off' value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityList.length ?
                                                    cityList.map((item, index) =>
                                                        <li key={index} onClick={() => selectCity(item)}>{item.name}</li>
                                                    ) :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container'>
                                        <label>نوع ناوگان</label>
                                        <div className='modal-dropdown-input-container' onClick={() => Dropdown("car")}>
                                            <input value={typeCar}/>
                                            {showDropdown === "car" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>
                                    {
                                        showDropdown === "car" &&
                                        <ul className="modal-dropdown-list">
                                            {loadingDropdown ?
                                                <LoadingForBtn />
                                                :
                                                typeCarList && typeCarList.map((item, index) =>
                                                    <li key={index} onClick={() => selectCar(item)}>{item.name}</li>
                                                )}

                                        </ul>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>


                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowAddCurator(false)}>لغو</button>
                    <button className='modal-btn modal-green-btn' onClick={addUser}>
                        {loadingForBtn ?
                            <LoadingForBtn/>
                            :
                            <>
                                <FaUserPlus className='icon'/>
                                افزودن</>
                        }
                    </button>
                </Modal.Footer>
            </Modal>


            {/*filter modal */}
            {/*filter modal */}
            <Modal
                show={showFilterModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{ width: '100%' }}>
                        <div className="modal-title-section">
                            <span className="modal-title">فیلتر کاربران</span>
                            <span className="hover-close-model" onClick={() => setShowFilterModal(false)}><IoMdClose/></span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">


                        <div className="row">
                            <div className="col">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        نام و نام خانوادگی
                                    </label>
                                    <input value={userFilter} onChange={(e) => setUserFilter(e.target.value)}/>
                                </div>

                            </div>

                            <div className="col">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        شماره موبایل
                                    </label>
                                    <input type="number" value={phoneNumberFilter} onChange={(e) => setPhoneNumberFilter(e.target.value)}/>

                                </div>

                            </div>


                        </div>


                    </div>

                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={setRemoveAllFilter}>حذف فیلترها
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={setFilterForApi}>
                        {loadingForBtn ? <LoadingForBtn /> :
                            <>
                                <IoFilter className='icon' style={{ marginLeft: '4px', fontSize: '15px' }} />
                                اعمال فیلتر</>}

                    </button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
