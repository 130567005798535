import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../../Components/Breadcrumbs/Breadcrumbs';
import InfoListNavigation from '../../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { FaPencil, FaRegTrashCan } from "react-icons/fa6";
import axios from "axios";
import { urlVariable } from "../../../Axios/Axios";
import Loading from "../../../Components/Loading/Loading";
import { IoFilter } from "react-icons/io5";
import { FaCheckCircle } from 'react-icons/fa';
import ToastComp from '../../../Components/Toast/ToastComp';
import LoadingForBtn from "../../../Components/Loading/LoadingForBtn/LoadingForBtn";
import { FaCircleXmark } from 'react-icons/fa6';
// import { RiImageCircleLine } from "react-icons/ri";
import { MdInsertPhoto } from "react-icons/md";
import Spinner from 'react-bootstrap/Spinner';
import editIcon from '../edit-image.svg'




export default function States() {

    const [loading, setLoading] = useState(false);
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [imageSrc, setImageSrc] = useState("")
    const [data, setData] = useState([]);
    const [searchCityShow, setSearchCityShow] = useState('');
    const [searchCityApi, setSearchCityApi] = useState('');
    const [stateId, setStateId] = useState("");
    const [imagePathToShow, setImagePathToShow] = useState("");
    const [loadingToAdd, setLoadingToAdd] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    //edit
    const [newStateName, setNewStateName] = useState("");
    const [updateAfterEdit, setUpdateAfterEdit] = useState(false);
    const [uploadedPic, setUploadedPic] = useState(false);
    const [selectedNewPic, setSelectedNewPic] = useState("");
    const [updateError, setUpdateError] = useState(false)
    const [lastImgId, setLastImgId] = useState("")

    // add
    const [showAddModal, setShowAddModal] = useState(false)
    const [addNewCityName, setAddNewCityName] = useState("")

    // toast
    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState('');
    const [toastBody, setToastBody] = useState('');
    const [toastIcon, setToastIcon] = useState();
    const [toastColor, setToastColor] = useState('');

    // loadings
    const [uploadImage, setUploadImage] = useState(false)

    console.log("state id :", stateId);

    const finalAdd = () => {
        axios.post(`${urlVariable}/admin/state/add`, {
            name: addNewCityName,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then(res => {
            setShowToast(true)
            setToastTitle("اضافه کردن شهر")
            setShowAddModal(false)
            setToastBody("اضافه کردن شهر با موفقیت انجام شد !")
            setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
            setToastColor(`var(--color-1)`)
            setAddNewCityName("")
            setUpdateAfterEdit(prev => !prev)
        }).catch(err => {
            if (err) {
                setShowToast(true)
                setToastTitle("اضافه کردن شهر")
                setToastBody("عملیات ناموفق بود !")
                setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                setToastColor('var(--danger-color)')
            }
        })
    }

    const finalEdit = () => {
        axios.put(`${urlVariable}/admin/state/update`, {
            name: newStateName,
            state: stateId
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then(res => {
            setShowToast(true)
            setToastTitle("ویرایش استان")
            setToastBody("ویرایش استان با موفقیت انجام شد !")
            setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
            setToastColor(`var(--color-1)`)
            setUpdateError(true)
            setUpdateAfterEdit(prev => !prev)
        }).catch(err => {
            if (err) {
                setShowToast(true)
                setToastTitle("ویرایش استان")
                setToastBody(err.response.data.msg)
                setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                setToastColor('var(--danger-color)')
                setUpdateError(true);
            }
        })
    }

    const changePhotoAfterAccept = () => {

        axios.post(`${urlVariable}/admin/image/setstateimg`, {
            stateID: stateId,
            imgID: imageSrc || lastImgId
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then(res => {
            console.log(res.data);
            setUploadedPic(prev => !prev);
        }).catch(err => {
            setShowToast(true);
            setToastTitle("خطا!");
            setToastBody(err.response.data.msg);
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />);
            setToastColor("var(--danger-color)");
        })
    }


    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setUploadImage(false)
        setSelectedNewPic("")
        setStateId("");
    };

    useEffect(() => {
        setLoading(true)
        axios.get(`${urlVariable}/api/v1/groups/states`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }, {
            params: {
                search: searchCityApi
            }
        })
            .then((res) => {
                setData(res.data.data);
                setLoading(false)
                console.log("state data is :", res.data);
            })
            .catch(err => {
                console.log(err)
                setLoading(true)
            })
    }, [searchCityApi, updateAfterEdit, uploadedPic])

    const prepareImage = (e, id) => {
        const formData = new FormData();
        const file = e.target.files[0];

        formData.append('attachedFile', file);

        setUploadImage(true);

        axios.post(`${urlVariable}/admin/uploadFile`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then(res => {
            setImageSrc(res.data.data._id.$oid);
            setUploadImage(false);
            setSelectedNewPic(res.data?.data?.source)
            console.log("image in axios-upload : ", res.data);
        }).catch(err => {
            console.log('err : ', err);

            setShowToast(true);
            setToastTitle("خطا!");
            setToastBody(err.response.data.msg);
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />);
            setToastColor("var(--danger-color)");
        });
    };


    console.log('image path to show url :', imagePathToShow);
    console.log('specific state id : ', stateId);

    function searchFilter() {
        setSearchCityApi(searchCityShow)
        setShowModalFilter(false)
    }

    const handleEditSubmit = () => {
        if (newStateName.trim() !== "" && updateError !== true) {
            finalEdit();
            changePhotoAfterAccept();
            setUploadImage(false)
            setShowEditModal(false)
        }
        else if (newStateName.trim() === "") {
            setShowToast(true);
            setToastTitle("خطا!");
            setToastBody("لطفا نام استان را وارد کنید!");
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />);
            setToastColor("var(--danger-color)");
        }
        else {
            setShowToast(true);
            setToastTitle("خطا!");
            setToastBody("لطفا اسم استان را تکراری وارد نکنید !")
            setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />);
            setToastColor("var(--danger-color)");
        }
    };


    return (
        <div className="info-list">
            <Breadcrumbs
                items={[
                    { name: 'اطلاعات پایه', link: '' },
                    { name: 'مدیریت استان‌ها', link: '/pannel/basicinfo/states' }
                ]}
            />
            <InfoListNavigation
                btntitle={loadingToAdd ? (<LoadingForBtn />) : 'افزودن استان جدید'}
                onShowModal={() => setShowAddModal(true)}
                hasFilter={true}
                onShowFilter={setShowModalFilter}
                items={[]}
            />

            <div className="info-list-table-section">
                <Container className='mx-0'>
                    <Row>
                        {loading ?
                            <Loading />
                            :
                            data.length ?
                                data.map((item, index) =>

                                    <Col xs={12} sm={6} md={4} lg={3} key={index}>
                                        <div className="basic-info-card">
                                            <div className='city-infos'>
                                                <div
                                                    style={{
                                                        width: '45px',
                                                        height: '45px',
                                                        border: '1px solid grey',
                                                        overflow: 'hidden',
                                                        borderRadius: '50%',
                                                        position: 'relative',
                                                        flexGrow: "0",
                                                        flexShrink: "0"
                                                    }}
                                                >


                                                    <>
                                                        {
                                                            item?.image[0]?.source ? (
                                                                <img
                                                                    style={{
                                                                        width: '45px',
                                                                        height: '45px',
                                                                        borderRadius: '50%',
                                                                    }}
                                                                    src={`${urlVariable}/${item?.image[0]?.source}`}
                                                                    onError={(e) => { e.target.style.display = 'none'; }}
                                                                />
                                                            ) : (
                                                                <MdInsertPhoto style={{ fontSize: "35px", margin: "4px", color: "var(--color-1)" }} />
                                                            )
                                                        }
                                                    </>

                                                </div>
                                                <div>
                                                    <div style={{ textAlign: "center" }}>{item.name}</div>
                                                </div>

                                                <div
                                                    onClick={() => {
                                                        setStateId(item._id.$oid)
                                                        setImagePathToShow(item?.image[0]?.source)
                                                        setNewStateName(item.name)
                                                        setShowEditModal(true)
                                                        setLastImgId(item?.image[0]?._id?.$oid)
                                                    }}
                                                    className='btns-container'
                                                >
                                                    <div className="edit-btn-container" >
                                                        <FaPencil />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                                :
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "var(--shadow-color)",
                                    padding: "15px 15px",
                                    borderRadius: "10px",
                                    fontWeight: "bold"
                                }}>
                                    استانی یافت نشد!..
                                </div>
                        }
                    </Row>
                </Container>

                {/* Toast */}
                <ToastComp
                    title={toastTitle}
                    body={toastBody}
                    icon={toastIcon}
                    onShowToast={setShowToast}
                    showToastValue={showToast}
                    toastColor={toastColor}
                />

                {/* Edit Modal */}

                <Modal show={showEditModal} centered>
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <div className="modal-title-section">
                                <span className="modal-title">ویرایش استان</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container style={{ textAlign: 'right', fontWeight: 'normal' }}>
                            <Row>
                                <Col xs={12}>
                                    <div className="modal-dropdown-container">

                                        {/* image container  */}
                                        <div
                                            style={{
                                                width: '65px',
                                                height: '65px',
                                                border: '1px solid grey',
                                                overflow: 'hidden',
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                                position: 'relative',
                                                margin: "10px auto",
                                            }}
                                        >
                                            {
                                                uploadImage ? (
                                                    <Spinner style={{ margin: "15px" }} animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                ) : (
                                                    <>
                                                        <img
                                                            style={{
                                                                width: '65px',
                                                                height: '65px',
                                                                borderRadius: '50%',
                                                            }}
                                                            alt="Edit State Image"
                                                            src={selectedNewPic || imagePathToShow ? `${urlVariable}/${selectedNewPic ? selectedNewPic : imagePathToShow}` : editIcon}
                                                        />
                                                    </>
                                                )
                                            }

                                            <input
                                                id="file-upload"
                                                type="file"
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    borderRadius: '50%',
                                                    zIndex: "5",
                                                    opacity: 0,
                                                    cursor: 'pointer',
                                                }}
                                                onChange={(e) => prepareImage(e, stateId)}
                                            />
                                        </div>
                                        {/* <h6 style={{ textAlign: "center" }}>ویرایش عکس</h6> */}

                                        {/* modal info  */}

                                        <div className='modal-input-container'>
                                            <label>نام استان</label>
                                            <div className='modal-dropdown-input-container'>
                                                <input
                                                    value={newStateName}
                                                    onChange={(e) => setNewStateName(e.target.value)}
                                                    type="text"
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <button className='modal-btn modal-cancle-btn' onClick={handleCloseEditModal}>بازگشت</button>
                        <button
                            onClick={handleEditSubmit}
                            className='modal-btn modal-green-btn'
                        >
                            تایید
                        </button>
                    </Modal.Footer>
                </Modal>

                {/* add state  */}

                <Modal
                    show={showAddModal}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <div className="modal-title-section">
                                <span className="modal-title">افزودن استان جدید</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <div className="col-sm">
                                <div className='modal-input-container'>
                                    <label style={{ textAlign: "right" }}>
                                        نام استان
                                    </label>
                                    <input type='text' onChange={(e) => setAddNewCityName(e.target.value)} value={addNewCityName} />
                                </div>
                            </div>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <button className='modal-btn modal-cancle-btn' onClick={() => setShowAddModal(false)}>لغو
                        </button>
                        <button onClick={() => {
                            if (addNewCityName) {
                                finalAdd()
                            }
                            else {
                                setShowToast(true)
                                setToastBody("لطفا نام استان را وارد کنید!")
                                setToastTitle("خطا!")
                                setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                                setToastColor('var(--danger-color)')
                                return null
                            }
                            setShowAddModal("false")
                        }}
                            className='modal-btn modal-green-btn'>
                            تایید
                        </button>
                    </Modal.Footer>
                </Modal>



                {/* ---------------------------- filter modal ------------------------------ */}
                <Modal
                    show={showModalFilter}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <div className="modal-title-section">
                                <span className="modal-title">فیلتر استان‌های کشور</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className='modal-input-container'>
                                        <label style={{ textAlign: "right" }}>
                                            استان
                                        </label>
                                        <input placeholder="استان مورد نظر خود را جست و جو کنید" value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)} />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <button className='modal-btn modal-cancle-btn' onClick={() => { setShowModalFilter(false); setSearchCityApi(""); setSearchCityShow("") }}>لغو فیلتر
                        </button>
                        <button className='modal-btn modal-green-btn' onClick={searchFilter}>
                            <>
                                <IoFilter className='icon' style={{ marginLeft: '4px', fontSize: '15px' }} />
                                اعمال فیلتر
                            </>
                        </button>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>
    )
}
