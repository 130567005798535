import React, {useEffect, useState} from 'react';
import Breadcrumbs from '../../../Components/Breadcrumbs/Breadcrumbs';
import InfoListNavigation from '../../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation';
import {Container, Row, Col, Modal} from 'react-bootstrap';
import {FaCircleXmark, FaPencil, FaRegTrashCan, FaTrash} from "react-icons/fa6";
import axios from "axios";
import {urlVariable} from "../../../Axios/Axios";
import Loading from "../../../Components/Loading/Loading";
import LoadingForBtn from "../../../Components/Loading/LoadingForBtn/LoadingForBtn";
import addNewCargo from "../../message/messageFromUser/AddNewCargo";
import {FaCheckCircle, FaPlusCircle} from "react-icons/fa";
import ToastComp from "../../../Components/Toast/ToastComp";





export default function Cars() {


    const [titleToast, setTitleToast] = useState("")
    const [bodyToast, setBodyToast] = useState("")
    const [icon, setIcon] = useState("")
    const [ShowToast, setShowToast] = useState("")
    const [ToastColor, setToastColor] = useState("")

    const [loading, setLoading] = useState(false);
    const [loadingBTN, setLoadingBTN] = useState(false);
    const [refreshData, setRefreshData] = useState(false)

    const [data, setData] = useState([]);

    const [showAddModal, setShowAddModal] = useState(false);
    const [newCar, setNewCar] = useState("");


    useEffect(() => {

        setLoading(true)
        axios.get(`${urlVariable}/api/v1/car/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        })
            .then((res) => {
                console.log(res.data);
                setData(res.data.data);
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }, [refreshData]);

    function addNewCar() {

        setLoadingBTN(true)
        axios.post(`${urlVariable}/admin/car/newcar`, {
            name: newCar,
        },{
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then((res) => {
            console.log(res)
            setTitleToast("عملیات موفق")
            setBodyToast(res.data.msg)
            setIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
            setToastColor('var(--color-1)')
            setShowToast(true)
            setLoadingBTN(false)
            setRefreshData(!refreshData)
            setShowAddModal(false)
            setNewCar("")
        }).catch((err) => {
            console.log(err)
            setTitleToast("عملیات ناموفق")
            setBodyToast(err.response.data.msg)
            setIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>)
            setToastColor('var(--danger-color)')
            setShowToast(true)
            setLoadingBTN(false)
        })

    }

    return (
        <div className="info-list">
            <Breadcrumbs
                items={[
                    {name: 'اطلاعات پایه', link: ''},
                    {name: 'مدیریت ناوگان', link: '/pannel/basicinfo/cars'}
                ]}
            />

            <ToastComp
                title={titleToast}
                body={bodyToast}
                icon={icon}
                onShowToast={setShowToast}
                showToastValue={ShowToast}
                toastColor={ToastColor}
            />

            <InfoListNavigation
                btntitle='افزودن ناوگان جدید'
                onShowModal={setShowAddModal}
                items={[]}
            />
            <div className="info-list-table-section">
                <Container className='mx-0'>
                    <Row>
                        {loading ?
                            <Loading/>
                            :
                            data && data.map((item) =>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <div className="basic-info-card">
                                        <div className='city-infos'>
                                            <div>
                                                <div>{item.name}</div>
                                            </div>
                                            <div className='btns-container'>
                                                <div className="edit-btn-container">
                                                    <FaPencil/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )}
                    </Row>
                </Container>
            </div>


            {/*add modal*/}
            <Modal
                show={showAddModal}
                centered
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">افزودن ناوگان جدید</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <div className="col-sm">

                            <div className='modal-input-container'>
                                <label style={{textAlign: "right"}}>
                                    نوع بار
                                </label>
                                <input
                                       onChange={(e) => setNewCar(e.target.value)}/>

                            </div>
                        </div>
                    </Row>

                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowAddModal(false)}>لغو
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={addNewCar}>

                        {loadingBTN ? <LoadingForBtn sytle={{fontSize: "10px"}}/> : (<> <FaPlusCircle className='icon'
                                                                                                 style={{
                                                                                                     marginLeft: '4px',
                                                                                                     fontSize: '15px'
                                                                                                 }}/> افزودن ناوگان جدید</>)}
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
