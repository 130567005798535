import React, { useState } from 'react';
import './Login.css';
import LoginImg from './public/logo2.png';
import { Modal } from 'react-bootstrap';
import {
    FaLock,
    FaUser,
    FaXmark,
    FaCircleExclamation
} from "react-icons/fa6";
import axios from 'axios';


export default function Login() {

    // Modal States
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [textErrorModal, setTextErrorModal] = useState('')

    // Values
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    function loginBtnClickHandler(e) {
        e.preventDefault()
        axios.post(`https://barkade.liara.run/admin/login/`, {
            username,
            password
        })
            .then((res) => {
                if (res.status === 200) {
                    localStorage.setItem("AccessToken", res.data.access_token)
                    window.location.pathname = '/pannel/users/all'
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    setTextErrorModal("کاربری با این مشخصات یافت نشد")
                    setShowErrorModal(true)
                }else{
                    setTextErrorModal("خطا در اتصال به اینترنت!")
                    setShowErrorModal(true)
                }
            })
    }

    return (
        <>
            <div className="login">
                <div className="right-side">
                    <div className="login-form-container">
                        <div className="login-title">
                            ورود به حساب کاربری
                        </div>
                        <form action="">
                            <label className='mt-3'>
                                <FaUser className='icon'/>
                                نام کاربری
                            </label>
                            <input
                                type="text"
                                placeholder='نام کاربری خود را وارد کنید ...'
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <label className='mt-4'>
                            <FaLock className='icon'/>
                                رمز عبور
                            </label>
                            <input
                                type="password"
                                placeholder='رمز عبور خود را وارد کنید ...'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="button-link-container mt-5">
                                <button onClick={loginBtnClickHandler}>ورود</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="left-side">
                    <img className='login-image' src={LoginImg} />
                </div>
            </div>

            {/* Error Modal */}
            <Modal
                show={showErrorModal}
            >
                <Modal.Header style={{ borderBottom: 'none' }}>
                    <Modal.Title style={{ width: '100%' }}>
                        <div className="modal-title-section">
                            <span style={{ fontSize: '15px' }}>
                                <FaCircleExclamation style={{ marginLeft: '5px', color: 'var(--danger-color)', fontSize: '16px' }} />
                                {textErrorModal}
                            </span>
                            <span className="modal-close-btn" onClick={() => setShowErrorModal(false)}><FaXmark /></span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
            </Modal>
        </>
    )
}
