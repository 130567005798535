import React, {useState, useEffect} from 'react';
import './GroupsList.css';
import InfoListNavigation from '../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation';
import {Modal, Row} from 'react-bootstrap';
import {Container, Col} from 'react-bootstrap';
import {
    FaTrash, FaChevronDown, FaUsers, FaUsersSlash, FaCircleXmark, FaXmark, FaTrashCan, FaCheck
} from "react-icons/fa6";
import {FaCheckCircle, FaClipboardList, FaPlusCircle, FaEdit} from 'react-icons/fa';
import {IoMdSettings} from "react-icons/io";
import Loading from '../../Components/Loading/Loading';
import axios from 'axios';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import ToastComp from '../../Components/Toast/ToastComp';
import {handleErrors, urlVariable} from '../../Axios/Axios';
import {useNavigate} from 'react-router-dom';
import DefaultPic from '../Login/public/logo2.png';
import LoadingForBtn from "../../Components/Loading/LoadingForBtn/LoadingForBtn";


export default function GroupsList() {


    const navigate = useNavigate()


    // Loading State
    const [loading, setLoading] = useState(false)
    const [loadingConnect, setLoadingConnect] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState(false)


    // Toast State
    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState('');
    const [toastBody, setToastBody] = useState('');
    const [toastIcon, setToastIcon] = useState();
    const [toastColor, setToastColor] = useState('');


    // Modal States
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showConnectionModal, setShowConnectionModal] = useState(false);
    // const [showEdit, setShowEdit] = useState(false)


    // simple states
    const [editItemId, setEditItemId] = useState("")
    const [deleteItemId, setDeleteItemId] = useState("")
    const [newGroupeName, setNewGroupeName] = useState("")
    const [newPlatform, setNewPlatform] = useState("")
    const [newGroupeId, setNewGroupeId] = useState("")
    const [showDropdown, setShowDropdown] = useState("")
    const [specificPlatform, setSpecificPlatform] = useState("telegram");
    const [isActive, setIsActive] = useState(true)


    // Flag State
    const [shouldUpdate, setShouldUpdate] = useState(false);


    // Get Group
    const [allGroups, setAllGroups] = useState([]);
    const [totalPages, setTotalPages] = useState("");
    const [totalDuc, setTotalDuc] = useState("")
    const [pageNumber, setpageNumber] = useState(1);
    const [refreshData, setRefreshData] = useState(false)


    useEffect(() => {
        const fetchGroups = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${urlVariable}/admin/bot/getGroupList`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
                    }, params: {
                        'is-active': isActive,
                        platform: specificPlatform,
                        page: pageNumber,
                        limit: 10,
                    },
                });
                setLoading(false);
                if (response.status === 200) {
                    console.log(response)
                    setAllGroups(response.data.data);
                    setTotalPages(response.data.total_pages);
                    setTotalDuc(response.data.total_count)
                }
            } catch (err) {
                handleErrors(err);
                setLoading(false);
                setToastTitle('عملیات ناموفق');
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                setToastColor('var(--danger-color)');
                setToastBody('خطا در اتصال اینترنت');
                setShowToast(true);
            }
        };
        fetchGroups();
    }, [shouldUpdate, specificPlatform, isActive, pageNumber, refreshData]);


    useEffect(() => {

        if (showAddModal) {
            setNewGroupeName("")
            setNewPlatform("")
            setNewGroupeId("")
            setTextPlatform("")
        }

    }, [showAddModal]);

    useEffect(() => {

        if ((pageNumber > totalPages) && (totalPages > 0) ) {
            setpageNumber(totalPages)
        }


    }, [pageNumber]);

    // Delete Group
    const deleteItem = (id) => {
        setShowDeleteModal(true);
        setDeleteItemId(id);
    };


    const finallyDelete = () => {
        setLoadingBtn(true)
        axios.delete(`${urlVariable}/admin/bot/deleteGroup`, {
            data: {
                id: deleteItemId
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
            }
        }).then((res) => {
            setLoadingBtn(false)
            console.log("success to delete");
            setShowDeleteModal(false);
            // Update group list after deletion
            setRefreshData(!refreshData)
            if (res.status === 200) {
                setShowToast(true)
                setToastTitle("حذف")
                setToastBody("عملیات حذف با موفقیت انجام شد !")
                setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
                setToastColor('var(--color-1)')
                setShouldUpdate(prev => !prev)
                setShowDeleteModal(false)
            }
        }).catch((err) => {
            handleErrors(err);
            setLoadingBtn(false)
            console.log(err);
            if (err.message === "Network Error") {
                setToastTitle('عملیات ناموفق');
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                setToastColor('var(--danger-color)');
                setToastBody('لطفا اینترنت خود را بررسی کنید!..');
                setShowToast(true);
            }
            if (err.response && err.response.status <= 499) {
                setToastTitle('عملیات ناموفق');
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                setToastColor('var(--danger-color)');
                setToastBody(err.response.data.msg);
                setShowToast(true);
            } else {
                if (err.response && err.response.status >= 500) {
                    setToastTitle('عملیات ناموفق');
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                    setToastColor('var(--danger-color)');
                    setToastBody("مشکلی در سرور رخ داده است لطفا دوباره تلاش کنید!..");
                    setShowToast(true);
                } else {
                    setToastTitle('عملیات ناموفق');
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                    setToastColor('var(--danger-color)');
                    setToastBody("خطا در اینترنت");
                    setShowToast(true);
                }
            }
        });
    };

    // Add Group
    const [textPlatform, setTextPlatform] = useState("")

    function clickDropdownItem(value, text) {
        setNewPlatform(value)
        setShowDropdown(false)
        setTextPlatform(text)
    }

    const finalAdding = () => {
        const newDataGroup = {
            name: newGroupeName,
            platform: newPlatform,
            group_id: Number(newGroupeId),
        }
        setLoadingBtn(true)
        axios.post(`${urlVariable}/admin/bot/newGroup`, newDataGroup, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`
            }
        }).then((res) => {
            setLoadingBtn(false)
            if (res.status === 201) {
                setShowToast(true)
                setToastTitle("افزودن گروه جدید")
                setToastBody("افزودن گروه با موفقیت انجام شد !")
                setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
                setShouldUpdate(prev => !prev)
                setToastColor('var(--color-1)')
            }
            setShowAddModal(false);
        }).catch((err) => {
            setLoadingBtn(false)
            console.log(err);
            handleErrors(err)
            if (err.message === "Network Error") {
                setToastTitle('عملیات ناموفق');
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                setToastColor('var(--danger-color)');
                setToastBody('لطفا اینترنت خود را بررسی کنید!..');
                setShowToast(true);
            }
            if (err.response && err.response.status <= 499) {
                setToastTitle('عملیات ناموفق');
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                setToastColor('var(--danger-color)');
                setToastBody(err.response.data.msg);
                setShowToast(true);
            } else {
                if (err.response && err.response.status >= 500) {
                    setToastTitle('عملیات ناموفق');
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                    setToastColor('var(--danger-color)');
                    setToastBody("مشکلی در سرور رخ داده است لطفا دوباره تلاش کنید!..");
                    setShowToast(true);
                } else {
                    setToastTitle('عملیات ناموفق');
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                    setToastColor('var(--danger-color)');
                    setToastBody("خطا در اینترنت");
                    setShowToast(true);
                }
            }
        });
    }


    // Connections Value & States
    const [hasConnection, setHasConnection] = useState([])
    const [hasConnectionID, setHasConnectionID] = useState('')

    // get id and name
    const [originGroupName, setOriginGroupName] = useState('')
    const [originGroupId, setOriginGroupId] = useState('')


    const [destinationGroupsName, setDestinationGroupsName] = useState([])
    const [destinationGroupsId, setDestinationGroupsId] = useState([])

    const [onEdited, setOnEdited] = useState(false)

    const [allAppGroups, setAllAppGroups] = useState([])
    const [searchGroupValue, setSearchGroupValue] = useState('')


    useEffect(() => {   // get all app group
        axios.get(`${urlVariable}/admin/groups`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setAllAppGroups(res.data.data);
                }
            })
            .catch(err => {
                handleErrors(err)
            })
    }, [])


    function openConnectonModalHandler(id, name) {
        setOriginGroupId(id)
        setOriginGroupName(name)
        setShowConnectionModal(true)
        setDestinationGroupsName([])
        showConnections(id)
    }


    function showConnections(id) {
        setLoadingConnect(true)
        axios.get(`${urlVariable}/admin/get-connections-group/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
            }
        })
            .then(res => {
                setLoadingConnect(false)
                const data = res.data.data;
                setHasConnection(data.length > 0 ? data[data.length - 1].destination_group : data)
                if (data.length > 0) {
                    setHasConnectionID(data[data.length - 1]._id?.$oid)
                } else setHasConnectionID("")
            })
            .catch(err => {
                handleErrors(err)
                setLoadingConnect(false)
                if (err.message === "Network Error") {
                    setToastTitle('عملیات ناموفق');
                    setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                    setToastColor('var(--danger-color)');
                    setToastBody('خطا در اتصال اینترنت');
                    setShowToast(true);
                }
            })

    }

    // useEffect(() => {
    //     if (hasConnection.length > 0) {
    //         console.log('render code' , hasConnection)
    //         hasConnection.map((item) => {
    //             setDestinationGroupsName([...destinationGroupsName, item.name])
    //         })
    //     }
    // }, [hasConnection]);


    // for use select group connect
    function clickGroupItemHandler(value) {
        console.log(hasConnection)
        let hasValue = false
        hasConnection.map((item) => {
            if (value._id?.$oid === item._id?.$oid)
                hasValue = true
        })
        if (!hasValue) {
            setHasConnection([...hasConnection, value])
        }
    }


    function removeOneConnect(value) {
        setHasConnection(hasConnection.filter((item) =>
            item !== value))
    }


    async function removeAllConnect() {
        setLoadingDelete(true)
        if (hasConnectionID) {
            await axios.delete(`${urlVariable}/admin/connections/delete`,
                {
                    data: {connection: hasConnectionID},
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
                    }
                })
                .then(res => {
                    console.log(res)
                    setLoadingDelete(false)
                    setShowToast(true)
                    setToastTitle("عملیات موفق")
                    setToastBody(res.data.msg)
                    setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--color-1)')
                    setShowConnectionModal(false)
                })
                .catch(err => {
                    handleErrors(err)
                    setLoadingDelete(false)
                    console.log(err)
                    if (err.message === "Network Error") {
                        setToastTitle('عملیات ناموفق');
                        setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                        setToastColor('var(--danger-color)');
                        setToastBody('لطفا اینترنت خود را بررسی کنید!..');
                        setShowToast(true);
                    }
                    if (err.response && err.response.status <= 499) {
                        setToastTitle('عملیات ناموفق');
                        setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                        setToastColor('var(--danger-color)');
                        setToastBody(err.response.data.msg);
                        setShowToast(true);
                    } else {
                        if (err.response && err.response.status >= 500) {
                            setToastTitle('عملیات ناموفق');
                            setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                            setToastColor('var(--danger-color)');
                            setToastBody("مشکلی در سرور رخ داده است لطفا دوباره تلاش کنید!..");
                            setShowToast(true);
                        } else {
                            setToastTitle('عملیات ناموفق');
                            setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                            setToastColor('var(--danger-color)');
                            setToastBody("خطا در اینترنت");
                            setShowToast(true);
                        }
                    }
                })
        } else {
            setLoadingDelete(false)
            setToastTitle('عملیات ناموفق');
            setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
            setToastColor('var(--danger-color)');
            setToastBody("ارتباطی برای حذف وجود ندارد");
            setShowToast(true);
        }

        await showConnections(originGroupId)
    }


    function addOrEditConnectGroup() {

        let arrayID = []

        if (hasConnectionID) {

            if (hasConnection.length > 0) {

                setLoadingBtn(true)
                hasConnection.map((item) => {
                    arrayID.push(item._id?.$oid)
                })

                const dataConnect = {
                    connection: hasConnectionID,
                    destination_group: arrayID
                }

                axios.put(`${urlVariable}/admin/connections/edit`, dataConnect, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
                    }
                })
                    .then(res => {
                        setLoadingBtn(false)
                        setShowToast(true)
                        setToastTitle("عملیات موفق")
                        setToastBody(res.data.msg)
                        setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
                        setToastColor('var(--color-1)')
                        setShowConnectionModal(false)
                    })
                    .catch(err => {
                        handleErrors(err)
                        setLoadingBtn(false)
                        if (err.message === "Network Error") {
                            setToastTitle('عملیات ناموفق');
                            setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                            setToastColor('var(--danger-color)');
                            setToastBody('لطفا اینترنت خود را بررسی کنید!..');
                            setShowToast(true);
                        }
                        if (err.response && err.response.status <= 499) {
                            setToastTitle('عملیات ناموفق');
                            setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                            setToastColor('var(--danger-color)');
                            setToastBody(err.response.data.msg);
                            setShowToast(true);
                        } else {
                            if (err.response && err.response.status >= 500) {
                                setToastTitle('عملیات ناموفق');
                                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                                setToastColor('var(--danger-color)');
                                setToastBody("مشکلی در سرور رخ داده است لطفا دوباره تلاش کنید!..");
                                setShowToast(true);
                            } else {
                                setToastTitle('عملیات ناموفق');
                                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                                setToastColor('var(--danger-color)');
                                setToastBody("خطا در اینترنت");
                                setShowToast(true);
                            }
                        }
                    })

            } else {
                setToastTitle('عملیات ناموفق');
                setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                setToastColor('var(--danger-color)');
                setToastBody('این گروه قبلا ارتباط داشته و نمیتواد گروه های مرتبط (مقصد) آن خالی باشد');
                setShowToast(true);
            }


        } else {

            setLoadingBtn(true)
            hasConnection.map((item) => {
                arrayID.push(item._id?.$oid)
            })

            const dataConnect = {
                origin_group: originGroupId,
                destination_group: arrayID
            }

            axios.post(`${urlVariable}/admin/connections/create`, dataConnect, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
                }
            })
                .then(res => {
                    setLoadingBtn(false)
                    setShowToast(true)
                    setToastTitle("عملیات موفق")
                    setToastBody(res.data.msg)
                    setToastIcon(<FaCheckCircle style={{marginLeft: '5px'}}/>)
                    setToastColor('var(--color-1)')
                    setShowConnectionModal(false)
                })
                .catch(err => {
                    handleErrors(err)
                    setLoadingBtn(false)
                    if (err.message === "Network Error") {
                        setToastTitle('عملیات ناموفق');
                        setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                        setToastColor('var(--danger-color)');
                        setToastBody('لطفا اینترنت خود را بررسی کنید!..');
                        setShowToast(true);
                    }
                    if (err.response && err.response.status <= 499) {
                        setToastTitle('عملیات ناموفق');
                        setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                        setToastColor('var(--danger-color)');
                        setToastBody(err.response.data.msg);
                        setShowToast(true);
                    } else {
                        if (err.response && err.response.status >= 500) {
                            setToastTitle('عملیات ناموفق');
                            setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                            setToastColor('var(--danger-color)');
                            setToastBody("مشکلی در سرور رخ داده است لطفا دوباره تلاش کنید!..");
                            setShowToast(true);
                        } else {
                            setToastTitle('عملیات ناموفق');
                            setToastIcon(<FaCircleXmark style={{marginLeft: '5px'}}/>);
                            setToastColor('var(--danger-color)');
                            setToastBody("خطا در اینترنت");
                            setShowToast(true);
                        }
                    }
                })
        }
    }


    return (<>
        <div className="info-list">
            <Breadcrumbs
                items={[{name: 'بارکپی', link: '/pannel/barcopy/groups'}, {
                    name: 'مدیریت گروه‌ها',
                    link: '/pannel/barcopy/groups'
                }]}
            />
            <div className='platform-btns-container'>
                <button
                    className={`${specificPlatform === "telegram" && "active"}`}
                    onClick={() => {
                        setSpecificPlatform("telegram")
                        setShouldUpdate(prev => !prev)
                    }}>تلگرام
                </button>

                <button
                    className={`${specificPlatform === "rubika" && "active"}`}
                    onClick={() => {
                        setSpecificPlatform("rubika")
                        setShouldUpdate(prev => !prev)
                    }}>روبیکا
                </button>

                <button
                    className={`${specificPlatform === "eita" && "active"}`}
                    onClick={() => {
                        setSpecificPlatform("eita")
                        setShouldUpdate(prev => !prev)
                    }}>ایتا
                </button>
            </div>

            <InfoListNavigation
                btntitle='افزودن گروه جدید'
                onShowModal={() => setShowAddModal(true)}
                setStatus={setIsActive}
                status={isActive}
                items={[['گروه‌های فعال', true, <FaUsers className='icon'/>],
                    ['گروه‌های غیرفعال', false, <FaUsersSlash className='icon'/>]]}
            />

            <div className="info-list-table-section">
                {loading ? <Loading/> : <table className='info-list-table'>
                    <thead>
                    <tr className='info-list-tr-head'>
                        <th>
                            <FaUsers style={{marginLeft: '3px', fontSize: '13px'}}/>
                            نام گروه
                        </th>
                        <th className='td-res-1'>
                            <FaClipboardList style={{marginLeft: '3px', fontSize: '13px'}}/>
                            شناسه
                        </th>
                        <th>
                            <IoMdSettings style={{marginLeft: '3px', fontSize: '13px'}}/>
                            عملیات
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {allGroups.length ? allGroups.map((value) => (
                        <tr key={value._id.$oid} className='info-list-row'>
                            <td>{value.name}</td>
                            <td className='td-res-1'>{value.group_id}</td>
                            <td>
                            <span style={{color: 'var(--color-1)', cursor: 'pointer'}}
                                  onClick={() => openConnectonModalHandler(value._id.$oid, value.name, value)}>
                              ارتباطات
                            </span>
                                <span style={{margin: '0 3px'}}>/</span>
                                <span onClick={() => deleteItem(value._id.$oid)}
                                      style={{color: 'var(--danger-color)', cursor: 'pointer'}}>
                              حذف
                            </span>
                            </td>
                        </tr>)) : <tr>
                        <td colSpan="3" style={{
                            textAlign: 'center', padding: '10px 0', backgroundColor: 'var(--bg-color-2)'
                        }}>در حال حاضر گروهی نداریم!
                        </td>
                    </tr>}
                    </tbody>
                </table>}
            </div>

            {/* Pagination Section */}
            {totalDuc < 10 ?
                null
                :
                <div className="pagination-section">
                    <button disabled={pageNumber <= 1} onClick={() => setpageNumber(pageNumber - 1)}
                            className="pagination-btn" style={pageNumber <= 0 ? {cursor: "not-allowed"} : null}>قبلی
                    </button>
                    <div>صفحه {pageNumber} از {totalPages}</div>
                    <button className='pagination-btn' style={pageNumber >= totalPages ? {cursor: "not-allowed"} : null}
                            disabled={pageNumber >= totalPages}
                            onClick={() => setpageNumber(pageNumber + 1)}>بعدی
                    </button>
                </div>
            }


        </div>

        {/* Toast */}
        <ToastComp
            title={toastTitle}
            body={toastBody}
            icon={toastIcon}
            onShowToast={setShowToast}
            showToastValue={showToast}
            toastColor={toastColor}
        />

        {/* Delete Modal */}
        <Modal show={showDeleteModal} centered>
            <Modal.Header>
                <Modal.Title style={{width: '100%'}}>
                    <div className="modal-title-section">
                        <span className="modal-title">حذف کاربر</span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                آیا از حذف گروه اطمینان دارید؟
            </Modal.Body>
            <Modal.Footer style={{borderTop: 'none'}}>
                <button className='modal-btn modal-cancle-btn' onClick={() => setShowDeleteModal(false)}>لغو
                </button>
                <button onClick={finallyDelete} className='modal-btn modal-red-btn'>
                    {loadingBtn ?
                        <LoadingForBtn/>
                        :
                        <>
                            <FaTrash className='icon'/>
                            حذف
                        </>
                    }

                </button>
            </Modal.Footer>
        </Modal>

        {/* Edit Modal */}
        {/* <Modal show={showEdit} centered>
        <Modal.Header>
          <Modal.Title style={{ width: '100%' }}>
            <div className="modal-title-section">
              <span className="modal-title">ویرایش</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container style={{ textAlign: 'right', fontWeight: 'normal' }}>
            <Row>
              <Col xs={12}>
                <div className='modal-input-container'>
                  <label>شناسه گروه</label>
                  <input type="text" value={groupId} onChange={(e) => setGroupId(e.target.value)} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className='modal-input-container'>
                  <label>نام گروه</label>
                  <input value={groupName} type="text" onChange={(e) => setGropuName(e.target.value)} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="modal-dropdown-container">
                  <div className='modal-input-container'>
                    <label>پلتفرم</label>
                    <div className='modal-dropdown-input-container'>
                      <input onClick={() => setShowDropdown(prev => !prev)} value={textPlatform} type="text" readOnly style={{ cursor: "pointer" }} />
                      <FaChevronDown className='icon' onClick={() => setShowDropdown(prev => !prev)} />
                    </div>
                  </div>
                  {
                    showDropdown &&
                    <ul className="modal-dropdown-list">
                      <li onClick={() => clickDropdownEditItem("telegram", "تلگرام")}>تلگرام</li>
                      <li onClick={() => clickDropdownEditItem("eita", "ایتا")}>ایتا</li>
                      <li onClick={() => clickDropdownEditItem("rubika", "روبیکا")}>روبیکا</li>
                    </ul>
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none' }}>
          <button className='modal-btn modal-cancle-btn' onClick={() => setShowEdit(false)}>بازگشت</button>
          <button className='modal-btn modal-green-btn' onClick={finalEdit}>
          <FaEdit className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>
            ویرایش
          </button>
        </Modal.Footer>
      </Modal> */}


        {/* Add a new group for bot */}
        <Modal show={showAddModal} centered>
            <Modal.Header>
                <Modal.Title style={{width: '100%'}}>
                    <div className="modal-title-section">
                        <span className="modal-title">اضافه کردن گروه</span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{textAlign: 'right', fontWeight: 'normal'}}>
                    <Row>
                        <Col xs={12}>
                            <div className='modal-input-container'>
                                <label>نام گروه</label>
                                <input type="text" onChange={(e) => setNewGroupeName(e.target.value)}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='modal-input-container'>
                                <label>شناسه گروه</label>
                                <input type="text" onChange={(e) => setNewGroupeId(e.target.value)}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="modal-dropdown-container">
                                <div className='modal-input-container'>
                                    <label>پلتفرم</label>
                                    <div className='modal-dropdown-input-container'>
                                        <input onClick={() => setShowDropdown(prev => !prev)} value={textPlatform}
                                               type="text" readOnly style={{cursor: "pointer"}}/>
                                        <FaChevronDown className='icon'
                                                       onClick={() => setShowDropdown(prev => !prev)}/>
                                    </div>
                                </div>
                                {showDropdown && <ul className="modal-dropdown-list">
                                    <li onClick={() => clickDropdownItem("telegram", "تلگرام")}>تلگرام</li>
                                    <li onClick={() => clickDropdownItem("eita", "ایتا")}>ایتا</li>
                                    <li onClick={() => clickDropdownItem("rubika", "روبیکا")}>روبیکا</li>
                                </ul>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer style={{borderTop: 'none'}}>
                <button className='modal-btn modal-cancle-btn' onClick={() => setShowAddModal(false)}>بازگشت
                </button>
                <button className='modal-btn modal-green-btn' onClick={finalAdding}>
                    {loadingBtn ?
                        <LoadingForBtn/>
                        :
                        <>
                            <FaPlusCircle className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>
                            افزودن
                        </>
                    }


                </button>
            </Modal.Footer>
        </Modal>


        {/* Connections Modal */}
        <Modal
            show={showConnectionModal} centered>
            <Modal.Header>
                <Modal.Title style={{width: '100%'}}>
                    <div className="modal-title-section">
                        <span className="modal-title">ارتباطات گروه "{originGroupName}"</span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{textAlign: 'right', fontWeight: 'normal'}}>
                    <Row>
                        <Col xs={12}>
                            <div style={{
                                fontSize: "13px",
                                marginBottom: "5px",
                                color: "var(--font-color-1)"
                            }}>
                                گروه های مرتبط (مقصد)
                            </div>
                            <div className='tag-container' style={{flexWrap: 'wrap'}}>
                                {loadingConnect ?
                                    <LoadingForBtn/>
                                    :
                                    hasConnection.length > 0 ?
                                    hasConnection.map((item, index) =>
                                        <div className='tag' key={index}>
                                            <div>{item.name}</div>
                                            <FaXmark className='icon' onClick={() => removeOneConnect(item)}/>
                                        </div>)
                                    :
                                    <div className='tag'>
                                        گروهی برای ارتباط ندارد!..
                                    </div>
                                }

                            </div>

                            <div className='modal-input-container' style={{marginTop: "16px"}}>
                                <label>گروه‌های اپلیکیشن</label>
                                <input type="text"
                                       placeholder='جستجو ...'
                                       style={{fontSize: '14px', padding: '5px 10px'}}
                                       value={searchGroupValue}
                                       onChange={(e) => setSearchGroupValue(e.target.value)}
                                />
                            </div>

                            <div className='group-list-container'>
                                {allAppGroups && allAppGroups.map(group => {
                                    return <div key={group._id.$oid} className='group-list-item'
                                                onClick={() => clickGroupItemHandler(group)}>
                                        <img src={group.image ? `${urlVariable}${group.image}` : DefaultPic}/>
                                        <span>{group.name}</span>
                                    </div>
                                })}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer style={{borderTop: 'none', display: 'flex', justifyContent: 'space-between'}}>
                <button className='modal-btn modal-red-btn' onClick={removeAllConnect}>
                    {loadingDelete ?
                        <LoadingForBtn/>
                        :
                        <>
                            <FaTrashCan className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>
                            حذف تمام ارتباط
                        </>
                    }
                </button>
                <div>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowConnectionModal(false)}>
                        بازگشت
                    </button>
                    <button className='modal-btn modal-green-btn me-2' onClick={addOrEditConnectGroup}>
                        {loadingBtn ?
                            <LoadingForBtn />
                            :
                            <>
                                <FaCheck className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>
                                ثبت
                            </>
                        }

                    </button>
                </div>
            </Modal.Footer>
        </Modal>

    </>);
}
