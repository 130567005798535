import React, { useEffect, useState } from 'react';
import './DropdownItem.css';
import { Link, useLocation } from 'react-router-dom';
import {
    FaAngleDown,
    FaAngleUp
} from "react-icons/fa";

export default function DropdownItem({ ...props }) {

    const [openDropdown, setOpenDropdown] = useState(false)
    const [subItems] = useState(props.subItems)
    const location = useLocation()

    function dropdownClickHandler() {
        if(props.itemState === 'hidden'){
            setOpenDropdown(false)
        } else {
            if (openDropdown) {
                setOpenDropdown(false)
            } else {
                setOpenDropdown(true)
            }
        }
    }

    function closeHandler(){
        props.clickHandler('hidden')
    }

    return (
        <li className="sidebar-item">
            <div className="sidebar-item-dropdown">
                <div className="dropdown-title-section" onClick={dropdownClickHandler}>
                    <div className="dropdown-title">
                        {props.icon}
                        <span className={`sidebar-item-title ${props.itemState}`}>{props.title}</span>
                    </div>
                    {openDropdown ?
                        <FaAngleUp className='dropdown-item-toggle' /> :
                        <FaAngleDown className={`dropdown-item-toggle ${props.itemState}`} />
                    }
                </div>
                {openDropdown && (
                    <ul className="dropdown-list">
                        {
                            subItems.map((item, index) => (
                                <Link key={index} className='link' to={item.sublink} onClick={closeHandler}>
                                    <li
                                        className={
                                            location.pathname === item.sublink ?
                                                'dropdown-item active' :
                                                'dropdown-item'
                                        }
                                    >
                                        {item.subtitle}
                                    </li>
                                </Link>
                            ))
                        }
                    </ul>)
                }
            </div>
        </li>
    )
}
