import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './lightMode.css';
// import './darkMode.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <>
            <App />
        </>
    </BrowserRouter>
);


