import React from 'react';
import './Breadcrumbs.css';
import { Link } from 'react-router-dom';
import {
    FaChevronLeft
} from "react-icons/fa6";

export default function Breadcrumbs({ items }) {
    return (
        <div className='breadcrumbs-container'>
            {
                items.map((item, index) => {
                    return (
                        <div key={index} style={{display: 'flex', alignItems: 'center'}}>
                            <FaChevronLeft className='icon' />
                            <Link className='link' to={item.link}>
                                <div>{item.name}</div>
                            </Link>
                        </div>
                    )
                })
            }

        </div>
    )
}
