import React, {useState, useEffect} from 'react';
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs";
import {FaChevronDown, FaChevronUp, FaCircleXmark, FaFileCircleCheck, FaFileCircleXmark} from "react-icons/fa6";
import Loading from "../../../Components/Loading/Loading";
import axios from "axios";
import {urlVariable} from "../../../Axios/Axios";
import LoadingForBtn from "../../../Components/Loading/LoadingForBtn/LoadingForBtn";
import {FaCheckCircle, FaPlusCircle} from "react-icons/fa";
import ToastComp from "../../../Components/Toast/ToastComp";


function AddNewCargo() {

    const [showToast, setShowToast] = useState(false)
    const [bodyToast, setBodyToast] = useState("")
    const [showSuccessfulToast, setShowSuccessfulToast] = useState(false)
    const [loadingBTN, setLoadingBTN] = useState(false)


    // value of city list from api
    const [cityName, setCityName] = useState([])
    const [searchCityShow, setSearchCityShow] = useState("")
    const [searchCityApi, setSearchCityAPi] = useState("")

    const [groupName, setGroupName] = useState()
    const [typeCarName, setTypeCarName] = useState()

    // value for edit data
    const [id, setId] = useState("")
    const [createdIn, setCreatedIn] = useState("");
    const [description, setDescription] = useState("");
    const [destination, setDestination] = useState("");
    const [destinationID, setDestinationID] = useState(""); // value for send api
    const [editOn, setEditOn] = useState("");
    const [group, setGroup] = useState("");
    const [origin, setOrigin] = useState("");
    const [originID, setOriginID] = useState("")  // value for send api
    const [phoneCall, setPhoneCall] = useState("");
    const [price, setPrice] = useState("");
    const [savecargo, setSavecargo] = useState("");
    const [status, setStatus] = useState("active");
    const [typeCar, setTypeCar] = useState("");
    const [typeCarID, setTypeCarID] = useState("");
    const [typeCargo, setTypeCargo] = useState("");
    const [weight, setWeight] = useState("");
    const [freeWeight, setFreeWeight] = useState(false)

    const [showDropDown, setShowDropDown] = useState("")
    const [loadingDropdown, setLoadingDropdown] = useState(false) // for city and group data


    useEffect(() => {

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/admin/groups`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then((res) => {
            // console.log(res);

            setGroupName(res.data.data);
            setLoadingDropdown(false)
        }).catch((err) => {
            setLoadingDropdown(true) // برای در حالت لودینگ بمونه که کاربر متوجه شه و دوباره رفرش کنه
            console.log(err)
        })

    }, []);

    useEffect(() => {

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/groups/city`, {
            params: {
                search : searchCityApi
            }
        })
            .then((res) => {
                setCityName(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingDropdown(true) //دلیل خط 117
            })

    }, [searchCityApi])


    useEffect(() => {

        const delayedSetData = setTimeout(() => {
            setSearchCityAPi(searchCityShow)
        }, 800)

        return () => clearTimeout(delayedSetData);

    }, [searchCityShow]);

    useEffect(() => {

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/car/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        })
            .then((res) => {
                setTypeCarName(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingDropdown(true)
            })

    }, [])


    function selectOrigin(value) {
        setOriginID(value._id.$oid)
        setOrigin(value.name)
        setSearchCityShow("")
        setSearchCityAPi("")
        setShowDropDown("")
    }

    function selectDestination(value) {
        setDestinationID(value._id.$oid)
        setDestination(value.name)
        setSearchCityShow("")
        setSearchCityAPi("")
        setShowDropDown("")
    }

    function selectTypeCar(value) {
        setTypeCarID(value._id.$oid)
        setTypeCar(value.name);
    }

    function dropdown(value) {
        if (showDropDown === value)
            setShowDropDown("")
        else setShowDropDown(value)
        setSearchCityShow("")
        setSearchCityAPi("")
    }

    function addNewCargo() {

        const newCargo = {
            data: [
                {
                    origin: originID,
                    freeweight: Boolean(freeWeight),
                    typeCargo: typeCargo,
                    description: description,
                    price: Number(price),
                    weight: Number(weight),
                    typeCar: typeCarID,
                    destination: destinationID,
                    phoneNumber: phoneCall
                }
            ]
        }

        setLoadingBTN(true)
        axios.post(`${urlVariable}/admin/cargo/create`, newCargo, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then((res) => {
            setShowToast(true)
            setBodyToast(res.data.msg)
            setShowSuccessfulToast(true)
            setLoadingBTN(false)
            setOrigin("")
            setDestination("")
            setTypeCar("")
            setTypeCargo("")
            setDescription("")
            setPhoneCall("")
            setWeight("")
            setFreeWeight(false)
            setPrice("")
        }).catch((err) => {
            setBodyToast(err.response.data.msg)
            setShowToast(true)
            setShowSuccessfulToast(false)
            setLoadingBTN(false)
        })
    }

    return (
        <div>
            <div className="info-list">
                <Breadcrumbs
                    items={[{name: 'پیام‌ها', link: '/pannel/message/fromUser'},
                        {name: 'افزودن بار جدید', link: ''}]}
                />

                <ToastComp
                    title={showSuccessfulToast ? "عملیات موفق" : "عملیات ناموفق"}
                    body={bodyToast}
                    icon={showSuccessfulToast ? <FaCheckCircle style={{marginLeft: '5px'}}/> :
                        <FaCircleXmark style={{marginLeft: '5px'}}/>}
                    onShowToast={setShowToast}
                    showToastValue={showToast}
                    toastColor={showSuccessfulToast ? 'var(--color-1)' : 'var(--danger-color)'}
                />

                <div className="container" style={{marginTop: "32px"}}>

                    <div className="row">

                        <div className="col-sm">

                            <div className="modal-dropdown-container" >
                                <div className='modal-input-container' onClick={() => dropdown("origin")}>
                                    <div style={{textAlign: "right"}}>
                                        مبدا
                                    </div>
                                    <div className='modal-dropdown-input-container'>
                                        <input value={origin}/>
                                        {showDropDown === "origin" ? (<FaChevronUp className='icon'/>) :
                                            (<FaChevronDown className='icon'/>)}
                                    </div>
                                </div>

                                {showDropDown === "origin" ?
                                    <ul className="modal-dropdown-list">
                                        <input className='search-dropdown-input' autoComplete="off" placeholder="جست و جو ..." value={searchCityShow}
                                               onChange={(e) => setSearchCityShow(e.target.value)}/>
                                        {loadingDropdown ?
                                            <LoadingForBtn />
                                            :
                                            cityName.length ? cityName.map((item, index) => (
                                                <li key={index} onClick={() => selectOrigin(item)}>
                                                    {item.name}
                                                </li>
                                            ))
                                                :
                                                <li>شهری یافت نشد!..</li>
                                        }
                                    </ul>
                                    : null}

                            </div>
                        </div>
                        <div className="col-sm">

                            <div className="modal-dropdown-container">
                                <div className='modal-input-container' onClick={() => dropdown("destination")}>
                                    <div style={{textAlign: "right"}}>
                                        مقصد
                                    </div>
                                    <div className='modal-dropdown-input-container'>
                                        <input value={destination}/>
                                        {showDropDown === "destination" ? (<FaChevronUp className='icon'/>) :
                                            (<FaChevronDown className='icon'/>)}
                                    </div>
                                </div>

                                {showDropDown === "destination" ?
                                    <ul className="modal-dropdown-list">
                                        <input className='search-dropdown-input' autoComplete="off" placeholder="جست و جو ..." value={searchCityShow}
                                               onChange={(e) => setSearchCityShow(e.target.value)}/>
                                        {loadingDropdown ?
                                            <LoadingForBtn />
                                            :
                                            cityName.length ?
                                                cityName.map((item, index) => (
                                                <li key={index} onClick={() => selectDestination(item)}>
                                                    {item.name}
                                                </li>
                                            ))
                                                :
                                                <li>شهری یافت نشد!..</li>

                                        }
                                    </ul>
                                    : null}


                            </div>
                        </div>


                    </div>

                    <div className="row" style={{marginTop: "16px"}}>
                        <div className="col-sm">

                            <div className="modal-dropdown-container" onClick={() => dropdown("typeCar")}>
                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        نوع ناوگان
                                    </label>

                                    <div className='modal-dropdown-input-container'>
                                        <input value={typeCar}/>
                                        {showDropDown === "typeCar" ? (<FaChevronUp className='icon'/>) :
                                            (<FaChevronDown className='icon'/>)}
                                    </div>
                                </div>
                                {showDropDown === "typeCar" ?
                                    <ul className="modal-dropdown-list">
                                        {loadingDropdown ?
                                            <Loading/>
                                            :
                                            typeCarName && typeCarName.map((item, index) => (
                                                <li key={index} onClick={() => selectTypeCar(item)}>
                                                    {item.name}
                                                </li>
                                            ))}
                                    </ul>
                                    : null}

                            </div>
                        </div>

                        <div className="col-sm">

                            <div className='modal-input-container'>
                                <label style={{textAlign: 'right'}}>
                                    نوع بار
                                </label>
                                <input value={typeCargo}
                                       onChange={(e) => setTypeCargo(e.target.value)}/>

                            </div>
                        </div>

                    </div>

                    <div className="row" style={{marginTop: "16px"}}>
                        <div className="col-sm">

                            <div className='modal-input-container'>
                                <label style={{textAlign: "right"}}>
                                    توضیحات
                                </label>
                                <input value={description}
                                       onChange={(e) => setDescription(e.target.value)}/>

                            </div>
                        </div>

                        <div className="col-sm">

                            <div className='modal-input-container'>
                                <label style={{textAlign: 'right'}}>
                                    شماره موبایل
                                </label>
                                <input type="number" value={phoneCall}
                                       onChange={(e) => setPhoneCall(e.target.value)}/>

                            </div>
                        </div>

                    </div>


                    <div className="row" style={{marginTop: "16px"}}>
                        <div className="col-sm">

                            <div className='modal-input-container'>
                                <label style={{textAlign: "right"}}>
                                    وزن (تن)
                                </label>
                                <input type="number" value={weight}
                                       onChange={(e) => setWeight(e.target.value)}/>
                                <div style={{marginTop: "4px", textAlign: "right"}}>
                                    <input style={{width: "auto"}} type="checkbox" id="freeWeight"
                                           checked={freeWeight}
                                           onChange={() => setFreeWeight(!freeWeight)}/>
                                    <label style={{marginRight: "4px"}} htmlFor="freeWeight">تناژ آزاد</label>
                                </div>

                            </div>
                        </div>


                        <div className="col-sm">

                            <div className='modal-input-container'>
                                <label style={{textAlign: "right"}}>
                                    قیمت
                                </label>
                                <input type="number" value={price}
                                       onChange={(e) => setPrice(e.target.value)}/>

                            </div>
                        </div>

                    </div>


                    <div style={{marginTop: "16px", display: "flex", justifyContent: "end", gap: "16px"}}>

                        <button className='modal-btn modal-green-btn' onClick={addNewCargo}>
                            {loadingBTN ?
                                <LoadingForBtn/>
                                :
                                (<><FaPlusCircle className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>افزودن
                                    بار
                                    جدید</>)
                            }
                        </button>
                    </div>

                </div>

            </div>

        </div>
    )

}


export default AddNewCargo