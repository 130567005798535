import React from 'react';
import './Pannel.css';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Header from '../../Components/Header/Header';
import { useRoutes } from 'react-router-dom';
import { routes2 } from '../../routes2';
import AllUsers from '../Users/AllUsers/AllUsers';


export default function Pannel() {

  const routers = useRoutes(routes2);

  return (
    <div className="pannel">
      <Sidebar />
      <div className="body-section">
        <div className="header-section">
          <Header />
        </div>
        <div className="content-section">
          {routers || <AllUsers />}
        </div>
      </div>
    </div>
  );
}
