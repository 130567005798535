import axios from "axios";

async function GET(url) {
    try {
        let config = {
            method: 'get',
            url: url,
            headers: await setHeaders()
        };
        return await axios(config)
            .then((response) => {
                return (response.data);
            })
            .catch((error) => {
                handleErrors(error)
            });
    } catch (error) {
        // console.log("GET_ERROR_2", error)
        return (error);
    }
}
async function POST(url, data) {
    try {
        let config = {
            method: "post",
            url: url,
            data: await data,
            headers: await setHeaders()
        }
        return await axios(await config)
            .then((response) => {
                return (response.data)
            })
            .catch(async (error) => {
                return handleErrors(error)
            })
    } catch (error) {
        // console.log("POST_ERROR", error)
        return (error)
    }
}
async function PUT(url, data) {
    try {
        let config = {
            method: "put",
            url: url,
            data: data,
            headers: await setHeaders()
        }
        return await axios(config)
            .then((response) => {
                return (response.data)
            })
            .catch((error) => {
                handleErrors(error)
            })
    } catch (error) {
        // console.log("PUT_ERROR", error);
        return (error)
    }
}
async function PATCH(url, data) {
    try {
        let config = {
            method: "patch",
            url: url,
            data: data,
            headers: await setHeaders()
        }
        return await axios(config)
            .then((response) => {
                return (response.data)
            })
            .catch((error) => {
                return handleErrors(error)
            })
    } catch (error) {
        // console.log("PATCH_ERROR", error)
        return (error)
    }
}

async function DELETE(url, data) {
    try {
        let config = {
            method: "delete",
            url: url,
            data: data,
            headers: await setHeaders()
        }
        return await axios(config)
            .then((response) => {
                return (response)
            })
            .catch((error) => {
                return handleErrors(error)
            })
    } catch (error) {
        // console.log("DELETE_ERROR", error)
        return (error)
    }
}
async function setHeaders() {
    if (localStorage.getItem("AccessToken")) {
        return { Authorization: "Bearer " + await localStorage.getItem("AccessToken") }
    }
    return { 'Content-Type': 'application/json' }
}
function handleErrors(error) {
    // console.log('ERROR_API', error)
    if (error.response && error.response.status == 401) {
        localStorage.clear();
        window.location.replace('/login')
    }
    return (error)
}

const urlVariable = 'https://barkade.liara.run'


export { GET, POST, PUT, PATCH, DELETE, setHeaders, urlVariable, handleErrors }