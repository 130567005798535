import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {urlVariable} from "../../Axios/Axios";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import InfoListNavigation from "../../Components/InfoListComponents/InfoListNavigation/InfoListNavigation";
import {
    FaChevronDown,
    FaChevronUp,
    FaCircleXmark,
    FaFileCircleCheck,
    FaFileCircleXmark,
    FaTrash
} from "react-icons/fa6";
import {FaPhone, FaUserTie, FaCheckCircle, FaEdit, FaPlusCircle} from "react-icons/fa";
import {IoMdSettings} from "react-icons/io";
import Loading from "../../Components/Loading/Loading";
import ToastComp from "../../Components/Toast/ToastComp";
import {Modal} from "react-bootstrap";
import LoadingForBtn from "../../Components/Loading/LoadingForBtn/LoadingForBtn";
import {PiSubtitlesFill} from "react-icons/pi";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";


function DriverChauffeur() {


    const datePickerRef = useRef()


    // Toast State
    const [showToast, setShowToast] = useState(false)
    const [bodyToast, setBodyToast] = useState("")
    const [showSuccessfulToast, setShowSuccessfulToast] = useState(false)

    // dropdown value
    const [showDropDown, setShowDropDown] = useState("")

    // Modal States
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)

    // value for data
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingBTN, setLoadingBTN] = useState(false)
    const [loadingDropdown, setLoadingDropdown] = useState(false) // for city and group data
    const [refreshData, setRefreshData] = useState(false)
    const [statusUrl, setStatusUrl] = useState("active")
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [totalData, setTotalData] = useState()


    // value of city list from api
    const [cityName, setCityName] = useState([])
    const [searchCityShow, setSearchCityShow] = useState("")
    const [searchCityApi, setSearchCityApi] = useState("")
    const [typeCarName, setTypeCarName] = useState([])

    // value for edit data
    const [id, setId] = useState("")
    const [name, setName] = useState("")
    const [status, setStatus] = useState({
        show: '',
        title: '',
    });
    const [createdIn, setCreatedIn] = useState("");
    const [type, setType] = useState()
    const [editOn, setEditOn] = useState("");
    const [city, setCity] = useState("");
    const [cityID, setCityID] = useState("")  // value for send api
    const [state, setState] = useState("");
    const [stateID, setStateID] = useState("")  // value for send api
    const [phoneCall, setPhoneCall] = useState("");
    const [typeCar, setTypeCar] = useState("");
    const [typeCarID, setTypeCarID] = useState("");
    const [certificateType, setCertificateType] = useState("")
    const [description, setDescription] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")


    useEffect(() => {

        setLoading(true)
        axios.get(`${urlVariable}/admin/chauffeur/getlist`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            },
            params: {
                role: "driver",
                limit: limit,
                page: page
            }
        })
            .then((res) => {
                console.log(res)
                setData(res.data.data);
                setTotalPage(res.data.total_pages)
                setTotalData(res.data.total_count)
                setLoading(false)
            })
            .catch(err => {
                setShowToast(true)
                setBodyToast("لطفا دوباره تلاش کنید!..")
                setShowSuccessfulToast(false)
                setLoading(false)
                if (err.message === "Network Error")
                    setBodyToast("اینترنت خود را بررسی کنید!..")
            })

    }, [refreshData, page]);


    useEffect(() => {

        if (showAddModal) {
            setName("")
            setPhoneCall("")
            setCertificateType("")
            setCity("")
            setCityID("")
            setTypeCar("")
            setTypeCarID("")
            setCreatedIn("")
            setEditOn("")
            setDescription("")
            setSearchCityShow("")

        }
    }, [showAddModal])


    useEffect(() => {
        setShowDropDown("")
    }, [showAddModal]);

    useEffect(() => {

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/groups/city`, {
            params: {
                search: searchCityApi
            }
        })
            .then((res) => {
                setCityName(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingDropdown(true) //دلیل خط 117
            })

    }, [searchCityApi])


    useEffect(() => {

        const delayedSetData = setTimeout(() => {
            setSearchCityApi(searchCityShow)
        }, 800)

        return () => clearTimeout(delayedSetData);

    }, [searchCityShow]);

    useEffect(() => {

        setLoadingDropdown(true)
        axios.get(`${urlVariable}/api/v1/car/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        })
            .then((res) => {
                setTypeCarName(res.data.data);
                setLoadingDropdown(false)
            })
            .catch(err => {
                console.log(err)
                setLoadingDropdown(true)
            })

    }, [])


    useEffect(() => {

        if ((page > totalPage) && (totalPage > 0))
            setPage(totalPage)

    }, [totalPage]);

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ' ';
        return Intl.DateTimeFormat('fa-IR').format(timestamp)
    };

    // function edit for edit modal
    function Edit(item) {

        console.log(item)
        setId(item._id.$oid || "-")
        setShowEditModal(true)
        setName(item.name || "-")
        setPhoneCall(item.phoneCall || "-")
        setCertificateType(item.certificateType || "-")
        if (item.status === "pending")
            setStatus({
                show: "در انتظار تایید",
                title: item.status,
            })
        setType("راننده")
        setCity(item.city[item.city.length - 1]?.name || "-")
        setCityID(item.city[item.city.length - 1]?._id?.$oid)
        setTypeCar(item.carType[item.carType.length - 1]?.name || "-")
        setTypeCarID(item.carType[item.carType.length - 1]?._id?.$oid)
        setCreatedIn(formatTimestamp(item.createdAt) || "-")
        setEditOn(formatTimestamp(item.editOn) || "-")
        setDescription(item.description || "-")
        setDateOfBirth(item.dateOfBirth || "-")
        setSearchCityShow("")
    }

    function selectCity(value) {
        setCityID(value._id.$oid)
        setCity(value.name)
        setSearchCityShow("")
    }

    function selectState(value) {
        setStateID(value._id.$oid)
        setState(value.name)
    }

    function selectTypeCar(value) {
        setTypeCarID(value._id.$oid)
        setTypeCar(value.name);
    }

    function dropdown(value) {
        if (showDropDown === value)
            setShowDropDown("")
        else setShowDropDown(value)
    }

    function Delete(item) {
        setId(item._id.$oid)
        setShowDeleteModal(true)
    }

    function deleteApi() {

        setLoadingBTN(true)
        axios.delete(`${urlVariable}/admin/chauffeur/delete`, {
            data: {
                chauffeur_id: id,
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then((res) => {
            console.log(res)
            setShowToast(true)
            setBodyToast(res.data.msg)
            setShowSuccessfulToast(true)
            setShowDeleteModal(false)
            setLoadingBTN(false)
            setRefreshData(!refreshData)
        }).catch((err) => {
            console.log(err)
            setBodyToast(err.response.data.msg)
            setShowToast(true)
            setShowSuccessfulToast(false)
            setShowDeleteModal(false)
            setLoadingBTN(false)
        })

    }

    // edited for send api
    function sendEditedToApi() {

        const edit = {
            chauffeur: id,
            name: name,
            phoneCall: phoneCall,
            city: cityID,
            carType: typeCarID,
            description: description,
            status: status.title,
            dateOfBirth: dateOfBirth?.year + "/" + dateOfBirth?.month?.number + "/" + dateOfBirth?.day === "undefined/undefined/undefined" ? dateOfBirth : dateOfBirth?.year + "/" + dateOfBirth?.month?.number + "/" + dateOfBirth?.day,
            certificateType: certificateType
        }
        setLoadingBTN(true)
        axios.put(`${urlVariable}/admin/chauffeur/edit`, edit, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
            }
        }).then((res) => {
            setShowToast(true);
            setBodyToast(res.data.msg);
            setShowSuccessfulToast(true);
            setLoadingBTN(false);
            setShowEditModal(false);
            setRefreshData(!refreshData);
        }).catch((err) => {
            setBodyToast(err.response.data.msg);
            setShowToast(true);
            setShowSuccessfulToast(false);
            setLoadingBTN(false);
        })

    }

    function addNewChauffeur () {

            const info = {
                name: name,
                phoneCall: phoneCall,
                city: cityID,
                carType: typeCarID,
                dateOfBirth: dateOfBirth?.year + "/" + dateOfBirth?.month?.number + "/" + dateOfBirth?.day === "undefined/undefined/undefined" ? dateOfBirth : dateOfBirth?.year + "/" + dateOfBirth?.month?.number + "/" + dateOfBirth?.day,
                certificateType: certificateType,
                description: description,
            }
            setLoadingBTN(true)
            axios.post(`${urlVariable}/admin/chauffeur/driver/create`, info, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
                }
            }).then((res) => {
                setShowToast(true)
                setBodyToast(res.data.msg)
                setShowSuccessfulToast(true)
                setLoadingBTN(false)
                setShowAddModal(false)
                setRefreshData(!refreshData)
            }).catch((err) => {
                setBodyToast(err.response.data.msg)
                setShowToast(true)
                setShowSuccessfulToast(false)
                setLoadingBTN(false)
            })
    }



    function selectStatus(value) {
        if (value === "فعال شده")
            setStatus({
                show: value,
                title: "active"
            })
        if (value === "غیر فعال شده")
            setStatus({
                show: value,
                title: "delete"
            })
        setShowDropDown("")
    }

    return (
        <>
            <div className="info-list">
                <Breadcrumbs
                    items={[{name: 'شوفریاب', link: '/pannel/Chauffeur'},
                        {name: 'آگهی رانندگان', link: '/pannel/Chauffeur/driver'}]}
                />

                <InfoListNavigation
                    btntitle='افزودن آگهی جدید'
                    onShowModal={setShowAddModal}
                    setStatus={setStatusUrl}
                    status={statusUrl}
                    items={[
                        ['آگهی های فعال', 'active', <FaFileCircleCheck className='icon'/>],
                        ['آگهی های غیرفعال', 'delete', <FaFileCircleXmark className='icon'/>],
                    ]}
                />


                <div className="info-list-table-section">
                    <table className='info-list-table'>
                        <thead>
                        <tr className='info-list-tr-head'>
                            <th>
                                <PiSubtitlesFill style={{marginLeft: '3px', fontSize: '16px'}}/>
                                عنوان
                            </th>
                            <th className='td-res-1'>
                                <FaPhone style={{marginLeft: '4px', fontSize: '13px'}}/>
                                شماره موبایل
                            </th>
                            <th className='td-res-1'>
                                <FaUserTie style={{marginLeft: '3px', fontSize: '13px'}}/>
                                نوع کاربری
                            </th>
                            <th>
                                <IoMdSettings style={{marginLeft: '3px', fontSize: '16px'}}/>
                                عملیات
                            </th>
                        </tr>
                        </thead>
                        <tbody>

                        {loading ?

                            (<tr>
                                <td colSpan="4"><Loading/></td>
                            </tr>)
                            :
                            (data.length ?
                                    data.map((value) =>
                                        <tr key={value._id.$oid} className='info-list-row'>
                                            <td>{value.name ?? "-"}</td>
                                            <td className='td-res-1'>{value.phoneCall ?? "-"}</td>
                                            <td className='td-res-1'>
                                                {value.type === "carOwner" && "صاحب ماشین"}
                                                {value.type === "driver" && "راننده"}
                                            </td>
                                            <td>
                                                    <span style={{color: 'var(--color-1)', cursor: 'pointer'}}
                                                          onClick={() => Edit(value)}>ویرایش</span>
                                                <span style={{margin: '0 3px'}}>/</span>
                                                <span style={{color: 'var(--danger-color)', cursor: 'pointer'}}
                                                      onClick={() => Delete(value)}>حذف</span>
                                            </td>
                                        </tr>
                                    ) :
                                    <tr>
                                        <td colSpan="4" style={{
                                            textAlign: 'center',
                                            padding: '10px 0',
                                            backgroundColor: 'var(--bg-color-2)'
                                        }}>در حال حاضر آگهی نداریم!
                                        </td>
                                    </tr>
                            )
                        }


                        </tbody>
                    </table>
                </div>

                {/* Pagination Section */}
                {totalData > limit ?
                    <div className="pagination-section">
                        <button className='pagination-btn' disabled={page <= 1}
                                style={page === 1 ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page - 1)}>قبلی
                        </button>
                        <div>صفحه {page} از {totalPage}</div>
                        <button className='pagination-btn' disabled={page >= totalPage}
                                style={page === totalPage ? {cursor: "not-allowed"} : null}
                                onClick={() => setPage(page + 1)}>بعدی
                        </button>
                    </div>
                    : null}

            </div>

            {/* Toast */}
            <ToastComp
                title={showSuccessfulToast ? "عملیات موفق" : "عملیات ناموفق"}
                body={bodyToast}
                icon={showSuccessfulToast ? <FaCheckCircle style={{marginLeft: '5px'}}/> :
                    <FaCircleXmark style={{marginLeft: '5px'}}/>}
                onShowToast={setShowToast}
                showToastValue={showToast}
                toastColor={showSuccessfulToast ? 'var(--color-1)' : 'var(--danger-color)'}
            />

            {/* Delete Modal */}
            <Modal
                show={showDeleteModal}
                centered
            >
            <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section">
                            <span className="modal-title">حذف آگهی</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    آیا از حذف این آگهی اطمینان دارید؟
                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowDeleteModal(false)}>لغو
                    </button>
                    <button className='modal-btn modal-red-btn' onClick={deleteApi}>

                        {loadingBTN ? <LoadingForBtn sytle={{fontSize: "10px"}}/> : (<> <FaTrash className='icon'
                                                                                                 style={{
                                                                                                     marginLeft: '4px',
                                                                                                     fontSize: '15px'
                                                                                                 }}/> حذف</>)}
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal */}
            <Modal
                show={showEditModal}
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section" style={{justifyContent: "space-around"}}>
                            <span className="modal-title">ویرایش آگهی</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">

                        <div className="row">
                            <div className="col">

                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            عنوان
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={name ?? "-"} onChange={(e) => setName(e.target.value)}/>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        شماره موبایل
                                    </label>
                                    <input value={phoneCall ?? "-"} onChange={(e) => setPhoneCall(e.target.value)}/>

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className="modal-dropdown-container" onClick={() => dropdown("Certificate")}>

                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            گواهینامه پایه
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={certificateType}/>
                                            {showDropDown === "Certificate" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "Certificate" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            <li onClick={() => setCertificateType("1")}>
                                                1
                                            </li>
                                            <li onClick={() => setCertificateType("2")}>
                                                2
                                            </li>
                                            <li onClick={() => setCertificateType("3")}>
                                                3
                                            </li>
                                        </ul>

                                        : null}

                                </div>

                            </div>

                        </div>

                        <div className="row">
                            <div className="col">

                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container' onClick={() => dropdown("status")}>
                                        <label style={{textAlign: "right"}}>
                                            وضعیت
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={status.show}/>
                                        </div>
                                    </div>
                                    {/*{showDropDown === "status" ?*/}
                                    {/*    <ul className="modal-dropdown-list"*/}
                                    {/*        style={{textAlign: "right"}}>*/}
                                    {/*        <li onClick={() => selectStatus("تاییده شده")}>*/}
                                    {/*            تاییده شده*/}
                                    {/*        </li>*/}
                                    {/*        <li onClick={() => selectStatus("تاییده نشده")}>*/}
                                    {/*            تاییده نشده*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*    : null}*/}
                                </div>

                            </div>

                            <div className="col-sm">

                                <div className="modal-dropdown-container">

                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                        نوع کاربری
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={type} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className="modal-dropdown-container" >

                                    <div className='modal-input-container' onClick={() => dropdown("city")}>
                                        <label style={{textAlign: "right"}}>
                                            شهر
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={city}/>
                                            {showDropDown === "city" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "city" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            <input className='search-dropdown-input' placeholder="جست و جو ..." autoComplete='off' value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ?
                                                    cityName.map((item, index) =>
                                                        <li key={index} onClick={() => selectCity(item)}>{item.name}</li>
                                                    ) :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>

                                        : null}

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className="modal-dropdown-container" onClick={() => dropdown("car")}>

                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            ناوگان
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={typeCar}/>
                                            {showDropDown === "car" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "car" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                (typeCarName && typeCarName.map((item, index) => (
                                                    <li key={index} onClick={() => selectTypeCar(item)}>
                                                        {item.name}
                                                    </li>
                                                )))}
                                        </ul>

                                        : null}

                                </div>
                            </div>


                        </div>


                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        ایجاد شده در
                                    </label>
                                    <input value={createdIn}/>

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        آخرین ویرایش
                                    </label>
                                    <input value={editOn}/>

                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        توضیحات
                                    </label>
                                    <input value={description} onChange={(e) => setDescription(e.target.value)}/>

                                </div>
                            </div>
                            <div className="col-sm">
                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        تاریخ تولد
                                    </label>
                                    <DatePicker
                                        value={dateOfBirth}
                                        onChange={setDateOfBirth}
                                        ref={datePickerRef}
                                        containerStyle={{
                                            backgroundColor: "var(--shadow-color)",
                                            borderRadius: "5px",
                                        }}
                                        style={{ //input style
                                            height: "100%"
                                        }}
                                        calendarPosition={`top-center`}
                                        fixMainPosition={false}
                                        fixRelativePosition={false}
                                        offsetY={0}
                                        offsetX={0}
                                        calendar={persian}
                                        locale={persian_fa}/>
                                </div>
                            </div>

                        </div>


                    </div>

                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>
                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowEditModal(false)}>لغو
                    </button>
                    <button className='modal-btn modal-green-btn'
                            onClick={sendEditedToApi}>
                        {loadingBTN ?
                            <LoadingForBtn/>
                            :
                            (<><FaEdit className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>ویرایش</>)
                        }
                    </button>
                </Modal.Footer>
            </Modal>


            {/* Add Modal */}
            <Modal
                show={showAddModal}
            >
                <Modal.Header>
                    <Modal.Title style={{width: '100%'}}>
                        <div className="modal-title-section" style={{justifyContent: "space-around"}}>
                            <span className="modal-title">افزودن آگهی</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">

                        <div className="row">
                            <div className="col">

                                <div className="modal-dropdown-container">
                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            عنوان
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input onChange={(e) => setName(e.target.value)}/>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        شماره موبایل
                                    </label>
                                    <input onChange={(e) => setPhoneCall(e.target.value)}/>

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        گواهینامه پایه
                                    </label>
                                    <input  onChange={(e) => setCertificateType(e.target.value)}/>

                                </div>
                            </div>

                        </div>


                        <div className="row">
                            <div className="col-sm">

                                <div className="modal-dropdown-container" >

                                    <div className='modal-input-container' onClick={() => dropdown("city")}>
                                        <label style={{textAlign: "right"}}>
                                            شهر
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={city}/>
                                            {showDropDown === "city" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "city" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            <input className='search-dropdown-input' placeholder="جست و جو ..." autoComplete='off' value={searchCityShow} onChange={(e) => setSearchCityShow(e.target.value)}/>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                cityName.length ?
                                                    cityName.map((item, index) =>
                                                        <li key={index} onClick={() => selectCity(item)}>{item.name}</li>
                                                    ) :
                                                    <li>شهری یافت نشد!..</li>
                                            }
                                        </ul>

                                        : null}

                                </div>
                            </div>

                            <div className="col-sm">

                                <div className="modal-dropdown-container" onClick={() => dropdown("car")}>

                                    <div className='modal-input-container'>
                                        <label style={{textAlign: "right"}}>
                                            ناوگان
                                        </label>
                                        <div className='modal-dropdown-input-container'>
                                            <input value={typeCar}/>
                                            {showDropDown === "car" ? (<FaChevronUp className='icon'/>) :
                                                (<FaChevronDown className='icon'/>)}
                                        </div>
                                    </div>

                                    {showDropDown === "car" ?
                                        <ul className="modal-dropdown-list" style={{textAlign: "right"}}>
                                            {loadingDropdown ?
                                                <LoadingForBtn/>
                                                :
                                                (typeCarName && typeCarName.map((item, index) => (
                                                    <li key={index} onClick={() => selectTypeCar(item)}>
                                                        {item.name}
                                                    </li>
                                                )))}
                                        </ul>

                                        : null}

                                </div>
                            </div>


                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        تاریخ تولد
                                    </label>
                                    <DatePicker
                                        value={dateOfBirth}
                                        onChange={setDateOfBirth}
                                        ref={datePickerRef}
                                        containerStyle={{
                                            backgroundColor: "var(--shadow-color)",
                                            borderRadius: "5px",
                                        }}
                                        style={{ //input style
                                            height: "100%"
                                        }}
                                        calendarPosition={`top-center`}
                                        fixMainPosition={false}
                                        fixRelativePosition={false}
                                        offsetY={0}
                                        offsetX={0}
                                        calendar={persian}
                                        locale={persian_fa}/>
                                </div>
                            </div>

                            <div className="col-sm">

                                <div className='modal-input-container'>
                                    <label style={{textAlign: "right"}}>
                                        توضیحات
                                    </label>
                                    <input onChange={(e) => setDescription(e.target.value)}/>

                                </div>
                            </div>

                        </div>


                    </div>

                </Modal.Body>
                <Modal.Footer style={{borderTop: 'none'}}>

                    <button className='modal-btn modal-cancle-btn' onClick={() => setShowAddModal(false)}>لغو
                    </button>
                    <button className='modal-btn modal-green-btn' onClick={addNewChauffeur}>
                        {loadingBTN ?
                            <LoadingForBtn/>
                            :
                            (<><FaPlusCircle className='icon' style={{marginLeft: '4px', fontSize: '15px'}}/>افزودن آگهی
                                جدید</>)
                        }
                    </button>

                </Modal.Footer>
            </Modal>
        </>
    )
}


export default DriverChauffeur;