import React, { useState, useEffect } from "react";
import "./Violation.css";
import { Modal, Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import {
    FaUser,
    FaUserCheck,
    FaUserLargeSlash,
    FaCircleXmark,
    FaTrash,
    FaChevronDown,
    FaTeamspeak,
} from "react-icons/fa6";
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import ToastComp from "../../Components/Toast/ToastComp";
import { FaDumpsterFire, FaObjectGroup, FaProcedures, FaCheckCircle } from "react-icons/fa";
import { MdOutlineGroups2 } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { GoArrowLeft } from "react-icons/go";
import { urlVariable } from "../../Axios/Axios";
import defaultImg from "../..///Assets/user.svg";
import { handleErrors } from "../../Axios/Axios";
import { LuUsers } from "react-icons/lu";
import { MdDescription } from "react-icons/md";
import { FaRoad } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";

const Violation = () => {
    // Toast State
    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastBody, setToastBody] = useState("");
    const [toastIcon, setToastIcon] = useState();
    const [toastColor, setToastColor] = useState("");

    // Modal States
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [allGroups, setAllGroups] = useState([]);

    // simple states
    const [groupeId, setGroupeId] = useState("");
    const [editItemId, setEditItemId] = useState("");
    const [deleteItemId, setDeleteItemId] = useState("");
    const [newGroupeName, setNewGroupeName] = useState("");
    const [newPlatform, setNewPlatform] = useState("");
    const [newGroupeId, setNewGroupeId] = useState("");
    const [groupeName, setGroupeName] = useState("");
    const [showDropdown, setShowDropdown] = useState("");
    const [insertNewName, setInsertNewName] = useState("");
    const [insertGroupId, setInserGroupId] = useState("");
    const [shouldUpdate, setShouldUpdate] = useState(false);

    const [deletedItem, setDeletedItem] = useState(false);


    // function deleteViolation() {
    //     const temp3 = {
    //       news_id: userId,
    //     };
    //     console.log(temp3);

    //     axios.delete(`${urlVariable}/admin/news/delete`, {
    //       data: temp3,
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
    //       },
    //     }

    //     )
    //       .then(res => {
    //         setShowDeleteModal(false);

    //         setIsNewsAdd(true);
    //         setToastTitle('عملیات موفق')
    //         setToastBody('خبر شما با موفقیت حذف شد')
    //         setToastIcon(<FaCheckCircle style={{ marginLeft: '5px' }} />)
    //         setToastColor('var(--color-1)')
    //         setShowToast(true)
    //         setIsNewsAdd(!isNewsAdd);
    //       }
    //       )
    //       .catch(err => {
    //         handleErrors(err)
    //         setToastTitle('عملیات ناموفق')
    //         setToastBody('خطا در بارگیری اطلاعات')
    //         setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
    //         setToastColor('var(--danger-color)')
    //         setShowToast(true)
    //       }
    //       )
    //   }

    const [groupImgProfile, setGroupImgProfile] = useState("");
    const [groupRepoter, setGroupRepoter] = useState("");
    const [groupRepoterType, setGroupRepoterType] = useState("");
    const [cargoType, setCargoType] = useState("");
    const [cargoPrice, setCargoPrice] = useState("");
    const [cargoUser, setCargoUser] = useState("");
    const [groupTitle, setGroupTitle] = useState("");
    const [groupDesc, setGroupDesc] = useState("");
    const [groupOrigin, setGroupOrigin] = useState("");
    const [groupdestination, setGroupdestination] = useState("");

    const showImg = (path) => {
        setShowDetail(true);
        setGroupImgProfile((path.user[0] && path.user[0].imagePath) ? path.user[0].imagePath : '-' );
        setGroupRepoter(path.user[0] ? path.user[0].name : '-');
        setGroupDesc(path.report ? path.report : '-');
        setGroupTitle(path.title ? path.title : '-');
        setGroupdestination(
            path.cargo && path.cargo.destination && path.cargo.destination[0]
                ? path.cargo.destination[0].name
                : "Unknown Destination"
        );
        setGroupOrigin(
            path.cargo && path.cargo.origin && path.cargo.origin[0]
                ? path.cargo.origin[0].name
                : "Unknown origin"
        );
       if(path.user && path.user[0] && path.user[0].userType)
       {
        path.user[0].userType === "driver"
        ? setGroupRepoterType("راننده")
        : path.user[0].userType === "curator"
            ? setGroupRepoterType("متصدی")
            : path.user[0].userType === "freight"
                ? setGroupRepoterType("باربر")
                : setGroupRepoterType("صاحب بار");
       }
       else
          setGroupRepoterType("مشخص نشده");

        setCargoType(path.cargo && path.cargo.typeCargo ? path.cargo.typeCargo : "مشخص نشده");
        setCargoPrice(path.cargo && path.cargo.price ? path.cargo.price : "مشخص نشده");

        // const temp = path.cargo && path.cargo.user ? path.cargo.user.$id.$oid : "مشخص نشده";
        // console.log(temp);
        // allUsers ? console.log(allUsers[0]._id) : console.log("edv");
        // if(allUsers )
        // {            
        //     const matchedUser = allUsers.find((value) => value._id && value._id.$oid === temp);
        //     setCargoUser(matchedUser && matchedUser.user && matchedUser.user.length > 0? matchedUser.user[0].name : "مشخص نشده");
        // }        
            // const matchedUser = allUsers
            // ? allUsers.find((value) => value._id && value._id.$oid === temp)
            // : null;

        // setCargoUser(matchedUser && matchedUser.user && matchedUser.user.length > 0
        //     ? matchedUser.user[0].name
        //     : "مشخص نشده");













    };
    useEffect(() => {
    }, [cargoUser]);
    const [userId, setUserId] = useState();
    const deleteHandler = (deleted) => {
        setShowDeleteModal(true);
        setUserId(deleted._id.$oid);
    };
    const finalDelete = (deleted) => {
        const temp = {
            report_id: userId
        };
        axios.delete(`${urlVariable}/admin/cargo/report/delete`,
            {
                data: temp,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
                },
            }
        )
            .then(res => {
                setShowDeleteModal(false);
                setDeletedItem(true);
                setToastTitle("عملیات موفق");
                setToastBody("حذف با موفقیت انجام شد.");
                setToastIcon(<FaCheckCircle style={{ marginLeft: "5px" }} />);
                setToastColor("var(--color-1)");
                setShowToast(true);
                setDeletedItem(!deletedItem);
            }
            ).catch(err => {
                handleErrors(err);
                if (err.message === "Network Error") {
                  setShowToast(true)
                  setToastTitle('عملیات ناموفق')
                  setToastBody('خطا در بارگیری اطلاعات لطفا اینترنت خود را بررسی کنید...!')
                  setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                  setToastColor('var(--danger-color)')
                }
                else if (err.response && err.response.status <= 499) {
                  setShowToast(true)
                  setToastTitle("عملیات ناموفق")
                  setToastBody(err.response.data.msg)
                  setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                  setToastColor('var(--danger-color)')
        
                } else {
                  if (err.response && err.response.status >= 500) {
                    setToastBody("خطای در سرور رخ داده لطفا دوباره تلاش کتید!..")
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')
        
        
                  } else {
                    setShowToast(true)
                    setToastTitle("عملیات ناموفق")
                    setToastBody("خطا در بارگیری اطلاعات")
                    setToastIcon(<FaCircleXmark style={{ marginLeft: '5px' }} />)
                    setToastColor('var(--danger-color)')
        
        
                  }
                }
            }
            )


    }



    // const finalAdding = () => {
    //   const newDataGroup = {
    //     report_id: newGroupeId,
    //   };
    //   axios
    //     .post("https://barkade.liara.run/admin/bot/newGroup", newDataGroup, {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
    //       },
    //     })
    //     .then((res) => {
    //       if (res.status === 200) {
    //         setShowToast(true);
    //         setToastTitle("افزودن");
    //         setToastBody("افزدن گروه با موفقیت انجام شد !");
    //       }
    //       console.log("success to add");
    //       setShouldUpdate((prev) => !prev);
    //     })
    //     .catch(() => {
    //       console.log("failed to add");
    //       setShowToast(true);
    //       setToastTitle("خطا");
    //       setToastBody("مشکلی پیش آمده است ! لطفا دوباره امتحان کنید...");
    //     });
    // };
    // const finalEdit = () => {
    //   axios
    //     .put(
    //       "https://barkade.liara.run/admin/bot/editGroup",
    //       {
    //         id: editItemId,
    //         group_id: insertGroupId,
    //         name: insertNewName,
    //       },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       console.log("success to add");
    //       setShouldUpdate((prev) => !prev);
    //       if (res.status === 200) {
    //         setShowToast(true);
    //         setToastTitle("ویرایش");
    //         setToastBody("ویرایش با موفقیت انجام شد !");
    //       }
    //     })
    //     .catch(() => {
    //       console.log("failed to add ");
    //       setShowToast(true);
    //       setToastTitle("خطا");
    //       setToastBody("مشکلی پیش آمده است ! لطفا دوباره امتحان کنید...");
    //     });
    // };

    useEffect(() => {
        axios
            .get(`${urlVariable}/admin/cargo/report_list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    setAllGroups(res.data.data);
                } else {
                    setToastTitle("عملیات ناموفق");
                    setToastBody("خطا در بارگیری اطلاعات");
                    setToastIcon(<FaCircleXmark style={{ marginLeft: "5px" }} />);
                    setToastColor("var(--danger-color)");
                    setShowToast(true);
                }
            })
            .catch((err) => {
                setToastTitle("عملیات ناموفق");
                setToastBody("خطا در بارگیری اطلاعات");
                setToastIcon(<FaCircleXmark style={{ marginLeft: "5px" }} />);
                setToastColor("var(--danger-color)");
                setShowToast(true);
            });
    }, [deletedItem]);


    return (
        <>
            <div className="violation-info-list info-list">
                <Breadcrumbs items={[{ name: "تخلفات", link: "/pannel/news" }]} />
                <div className="info-list-table-section">
                    {allGroups.length ? (
                        <table className="info-list-table">
                            <thead>
                                <tr className="info-list-tr-head">
                                    <th>
                                        <LuUsers
                                            style={{ marginLeft: "3px", fontSize: "13px" }}
                                        />
                                        متخلفان
                                    </th>
                                    <th className="td-res-1">
                                        <MdDescription
                                            style={{ marginLeft: "3px", fontSize: "13px" }}
                                        />
                                        عنوان
                                    </th>
                                    <th>
                                        <FaRoad
                                            style={{ marginLeft: "3px", fontSize: "13px" }}
                                        />
                                         مسیر
                                    </th>
                                    <th>
                                        <IoMdSettings
                                            style={{ marginLeft: "3px", fontSize: "13px" }}
                                        />
                                        عملیات
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allGroups &&
                                    allGroups.map((value, index) => {
                                        const userName =
                                            value.user && value.user[0] ? value.user[0].name : "";
                                        const userPhone =
                                            value.user && value.user[0]
                                                ? value.user[0].phoneNumber
                                                : "";
                                        const cargoOrigin =
                                            value.cargo && value.cargo.origin && value.cargo.origin[0]
                                                ? value.cargo.origin[0].name
                                                : "";
                                        const cargoDestination =
                                            value.cargo &&
                                                value.cargo.destination &&
                                                value.cargo.destination[0]
                                                ? value.cargo.destination[0].name
                                                : "";

                                        return (
                                            <tr className="info-list-row" key={index}>
                                                <td className="td-res-1 img-container users-and-numbers">
                                                    <img src={defaultImg} alt="" />
                                                    <div className="infoUser">
                                                        <p style={{ color: "black", marginTop: "2px" }}>
                                                            {userName}
                                                        </p>
                                                        <p style={{ fontSize: "14px" }}>{userPhone}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="illegal-crate">
                                                        <p style={{ color: "#D30D0D" }}>{value.title}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="violation-citiesNames">
                                                        <p>{cargoOrigin}</p> - <p>{cargoDestination}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="violation-show-delete">
                                                        <button
                                                            className="violation-showDetail"
                                                            onClick={() => showImg(value)}
                                                        >
                                                            نمایش /
                                                        </button>
                                                        <button
                                                            onClick={() => deleteHandler(value)}
                                                            className="violation-deleteDetail"
                                                        >
                                                            حذف
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    ) : (
                        <Loading />
                    )}
                </div>

                {/* Pagination Section */}
                <div className="pagination-section">
                    <div className="pagination-btn">قبلی</div>
                    <div>صفحه 1 از 1</div>
                    <div className="pagination-btn">بعدی</div>
                </div>
            </div>

            {/* Toast */}
            <ToastComp
                title={toastTitle}
                body={toastBody}
                icon={toastIcon}
                onShowToast={setShowToast}
                showToastValue={showToast}
                toastColor={toastColor}
            />

            {/* delete Modal */}
            <Modal show={showDeleteModal} centered>
                <Modal.Header>
                    <Modal.Title style={{ width: "100%" }}>
                        <div className="modal-title-section">
                            <span className="modal-title">حذف تخلف</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>آیا از حذف تخلف اطمینان دارید؟</Modal.Body>
                <Modal.Footer style={{ borderTop: "none" }}>
                    <button
                        className="modal-btn modal-cancle-btn"
                        onClick={() => setShowDeleteModal(false)}
                    >
                        لغو
                    </button>
                    <button onClick={finalDelete} className="modal-btn modal-red-btn">
                        <FaTrash className="icon" />
                        حذف
                    </button>
                </Modal.Footer>
            </Modal>

            {/* show detail Modal */}
            <Modal show={showDetail} centered>
                <Modal.Header>
                    <Modal.Title style={{ width: "100%" }}>
                        <div className="modal-title-section">
                            <span className="modal-title">اطلاعات تخلف</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{ textAlign: "right", fontWeight: "normal" }}>
                        <Row>
                            <Col xs={12}>
                                <div>
                                    {/* profile  */}
                                    <div className="violator-details-profile">
                                        <img
                                            style={{
                                                margin: "auto",
                                                backgroundColor: "#DFDFDF",
                                                borderRadius: "50%",
                                                padding: "10px",
                                            }}
                                            src={defaultImg}
                                            width="100px"
                                            color="lightgray"
                                            alt=""
                                        />
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <p style={{ marginTop: "10px" }}>
                                                <span style={{ fontWeight: "800" }}>
                                                    گزارش دهنده :{" "}
                                                </span>
                                                {groupRepoter} ({groupRepoterType})

                                            </p>
                                        </div>
                                    </div>
                                    {/* details  */}
                                    <div className="violator-details-panel">
                                        <div className="violation-field">
                                            <p>عنوان  : {groupTitle ? groupTitle : '-'}</p>
                                        </div>
                                        <div className="violation-field">
                                            <p
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {groupOrigin} <span>......</span>
                                                <GoArrowLeft style={{ fontSize: "25px" }} />
                                                <span>.......</span> {groupdestination}
                                            </p>
                                        </div>
                                        <div className="violation-field">
                                            <p>گزارش : {groupDesc ? groupDesc : '-' }</p>
                                        </div>
                                        <div className="violation-field">
                                            <p>نوع بار : {cargoType ? cargoType : '-'}</p>
                                        </div>
                                        <div className="violation-field">
                                            <p>قیمت : {cargoPrice ? cargoPrice : '-'}</p>
                                        </div>
                                        <div className="violation-field">
                                            <p>متخلف : {cargoUser ? cargoUser : "-"}</p>
                                        </div>
                                        <button className="violation-blockBtn">مسدود</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{ borderTop: "none" }}>
                    <button
                        className="modal-btn modal-cancle-btn"
                        onClick={() => setShowDetail(false)}
                    >
                        بازگشت
                    </button>
                </Modal.Footer>
            </Modal>

            {/* add a new groupe */}

            {/* <Modal show={showAddModal} centered>
        <Modal.Header>
          <Modal.Title style={{ width: '100%' }}>
            <div className="modal-title-section">
              <span className="modal-title">اضافه کردن گروه</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container style={{ textAlign: 'right', fontWeight: 'normal' }}>
            <Row>
              <Col xs={12}>
                <div className='modal-input-container'>
                  <label>نام گروه</label>
                  <input type="text" onChange={(e) => setNewGroupeName(e.target.value)} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className='modal-input-container'>
                  <label>شناسه گروه</label>
                  <input type="text" onChange={(e) => setNewGroupeId(e.target.value)} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="modal-dropdown-container">
                  <div className='modal-input-container'>
                    <label>پلتفرم</label>
                    <div className='modal-dropdown-input-container'>
                      <input onClick={() => setShowDropdown(prev => !prev)} value={newPlatform} type="text" readOnly style={{ cursor: "pointer" }} />
                      <FaChevronDown className='icon' onClick={() => setShowDropdown(prev => !prev)} />
                    </div>
                  </div>
                  {
                    showDropdown &&
                    <ul className="modal-dropdown-list">
                      <li onClick={() => setNewPlatform("telegram")}>Telegram</li>
                      <li onClick={() => setNewPlatform("eita")} value="eitaa">Eitaa</li>
                    </ul>
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none' }}>
          <button className='modal-btn modal-cancle-btn' onClick={() => setShowAddModal(false)}>بازگشت</button>
          <button className='modal-btn modal-green-btn' >
            <FaTrash className='icon' />
            تایید
          </button>
        </Modal.Footer>
      </Modal> */}

            {/* Toast for notif */}

            <ToastComp
                title={toastTitle}
                body={toastBody}
                icon={toastIcon}
                onShowToast={setShowToast}
                showToastValue={showToast}
                toastColor={toastColor}
            />
        </>
    );
};

export default Violation;
